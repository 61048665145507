import React, {useContext, useEffect, useState} from 'react';
import ProductItem from './ProductItem';
import "./ProductsGrid.scss";
import { ProductsContext } from '../../contexts/ProductsContext';
import {postProductsInStock} from "../Api";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import Spinner from "../Spinner/Spinner";

const ProductsGrid = () => {
    const [auth, authDispatch] = useAuthentication();
    const { products} = useContext(ProductsContext)
    useEffect(() => {
        authDispatch({type: 'setLoading', payload: true});
        postProductsInStock().then((response) => {
            authDispatch({type: 'setProductsInStock', payload: response.data.stock});
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            authDispatch({type: 'setLoading', payload: false});
        })
    }, [])

    return (
        <div className={'ProductsGrid'} id={'ProductsGrid'}>
            <section className={'grid-container'}>
                <div className={'grid-x grid-padding-x'}>
                    <div className={`large-12 cell ${!auth.loading ? 'hide' : null}`}>
                        <h2 className={'text-center'}>Bitte warten&hellip;</h2>
                        <Spinner loading={auth.loading} />
                    </div>
                    {
                        products.map(product => (
                            <ProductItem key={product.id} product={product}/>
                        ))
                    }
                </div>
            </section>
        </div>
     );
}

export default ProductsGrid;
