import React from 'react';
import {Route as RouteSrc} from 'react-router-dom';
import {createElement} from '../../utils/component';

const Route = route => {
    const {component, path, routes, exact} = route;
    return (
        <RouteSrc
            exact={exact !== false}
            path={path}
            render={props => createElement(component, {...props, routes})}
        />
    );
};

export default Route;
