import React from 'react';
import Route from './Route';

const Routes = props => {
    const {routes} = props;
    if (Array.isArray(routes) && routes.length > 0) {
        return routes.map((route, i) => (<Route key={i} {...route}/>));
    }
    return null;
};

export default Routes;
