import React from "react";
import {useHistory} from "react-router-dom";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import dateDiffCalc from "../../utils/dateDiff";


const GeneralErrorMessage = props => {
    const history = useHistory();
    const [auth, authDispatch] = useAuthentication();
    const retry = () => {
        dateDiffCalc();
        authDispatch({type: 'setCartAge', payload: localStorage.getItem('cartAge')});
        authDispatch({type: 'setRetryPayment', payload: true});
        history.push('/registrierung');
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
        authDispatch({type: 'setCorrectIssuer', payload: false});
    }

    return (
        <div className={'general-error-msg'}>
            <img src={require('../../assets/images/icons/error.png')} alt={''} />
            <h2>Es gab leider ein Problem</h2>
            <p>{props.errorMessage}</p>
            <p>
                <span>Bitte versuchen Sie es </span>
                <a onClick={retry}>hier</a>
                <span> erneut.</span>
            </p>
        </div>
    )
}

export default GeneralErrorMessage;
