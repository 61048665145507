import React, {useContext, useEffect, useState} from "react";
import "./CheckoutPaymentScreen.scss";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import Adyen from "../components/Adyen/Adyen";
import {useAuthentication} from "../components/Authentication/AuthenticationProvider";
import Video from "../components/Video/Video";
import {postConfirmEmail, postPermission, postState} from "../components/Api";
import {useHistory} from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import {CartContext} from "../contexts/CartContext";
import {useChangeEffect} from "../hooks/UseChangeEffect";
import {maximumCartAge, voucher} from "../services/products";
import dateDiffCalc from "../utils/dateDiff";
import AuthGuard from "../components/Authentication/AuthGuard";

const CheckoutPaymentScreen = props => {
    const [contentType, setContentType] = useState();
    const [auth, authDispatch] = useAuthentication();
    const {initNewCart, restoreCart} = useContext(CartContext);
    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState();
    const [userUuid, setUserUuid] = useState();
    const [cartUuid, setCartUuid] = useState(localStorage.getItem('cartUuid'));
    const [threeDsOneResultStep, setThreeDsOneResultStep] = useState();
    const history = useHistory();
    const search = history.location.search;
    const paramCartUuid = '&cartUuid';
    const paramUserUuid = '?userUuid';
    const [cartAge, setCartAge] = useState();
    const [executePermission, setExecutePermission] = useState(false);
    const threeDsSuccessfull = auth.successfulThreeDSAuth;
    const correctIssuer = auth.correctIssuer;
    const cardAllowedToPlay = auth.cardAllowedToPlay;
    useEffect(() => {
        setExecutePermission(true);
        dateDiffCalc();
        setCartAge(localStorage.getItem('cartAge'));
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
        authDispatch({type: 'setCardAllowedToPlay', payload: false});
        authDispatch({type: 'setCorrectIssuer', payload: false});
    }, [history.location, authDispatch]);
    useEffect(() => {
        const checkIfProductIsStillInStock = setInterval(() => {
            if(history.location.pathname === '/kasse'){
                if(window.threeDsSuccessfull === true && window.cardAllowedToPlay && window.correctIssuer) {
                    clearInterval(checkIfProductIsStillInStock);
                } else {
                    const data = {
                        "userUuid": auth.userUuid,
                        "cartUuid": localStorage.getItem('cartUuid')
                    }
                    postPermission('/kasse', data.userUuid, data.cartUuid).then((response) => {
                        if(!response.data.hasPermission) {
                            authDispatch({type: 'setRenewCart', payload: true});
                            history.push('/warenkorb');
                            authDispatch({type: 'setRenewCart', payload: false});
                        } else {
                            dateDiffCalc();
                            setCartAge(localStorage.getItem('cartAge'));
                        }
                    }).catch((error) => {
                        console.log(error.response);
                    })
                }
            } else {
                clearInterval(checkIfProductIsStillInStock);
                window.threeDsSuccessfull = null;
            }
        }, 15000);
    }, [])
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission) {
            setExecutePermission(false);
        }
    }, [executePermission])
    useChangeEffect({threeDsSuccessfull, cardAllowedToPlay, correctIssuer}, (v) => {
        window.threeDsSuccessfull = v.threeDsSuccessfull ? true : null;
        window.cardAllowedToPlay = v.cardAllowedToPlay;
        window.correctIssuer = v.correctIssuer;
    }, [threeDsSuccessfull, cardAllowedToPlay, correctIssuer])
    useChangeEffect({cartAge}, (v) => {
        if(v.cartAge > maximumCartAge) {
            const newCartData = {};
            postState(newCartData).then((response) => {
                initNewCart(response.data.cartUuid);
                localStorage.setItem('cartAge', 0);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                history.push('/warenkorb');
            })
        }
    }, [cartAge])
    useEffect(() => {
        if(search.includes(paramUserUuid) && search.includes(paramCartUuid)) {
            setLoading(true);
            setTask('e-mail-confirm');
            const searchValues = search.split('?userUuid=')[1].split('&cartUuid=');
            setUserUuid(searchValues[0]);
            setCartUuid(searchValues[1]);
        } else if(!search.includes(paramUserUuid) && search.includes('?cartUuid')) {
            setLoading(true);
            setTask('three-ds-one-redirect');
            setCartUuid(search.split('?cartUuid=')[1]);
        }
    }, [search]);
    useChangeEffect({task}, (v) => {
        switch (v.task) {
            case 'e-mail-confirm':
                postConfirmEmail(userUuid, cartUuid).then((response) => {
                    const data = {"userUuid": response.data.userUuid, "cartUuid": cartUuid}
                    postState(data).then((response) => {
                        let allowedToPlay = response.data.allowedToPlay;
                        let emailIsVerified = response.data.emailIsVerified;
                        let emailIsBlacklisted = response.data.isEmailBlacklisted;
                        let registrationIsExpired = response.data.isRegistrationExpired;
                        authDispatch({type: 'setUserUuid', payload: response.data.userUuid});
                        if(emailIsBlacklisted) {
                            history.push('/teilnahme-nicht-moeglich');
                        } else if(registrationIsExpired) {
                            history.push('/registrierung-abgelaufen');
                        } else if(!emailIsVerified) {
                            history.push('/bereits-registriert');
                        } else if(!allowedToPlay && emailIsVerified) {
                            history.push('/bereits-teilgenommen');
                        } else if(allowedToPlay && emailIsVerified) {
                            switch (response.data.cartContents.length) {
                                case 0:
                                    localStorage.setItem('cartUuid', response.data.cartUuid);
                                    history.push('/warenkorb');
                                    break;
                                default:
                                    voucher.forEach(value => {
                                        if(value.id === response.data.cartContents[0].productId) {
                                            localStorage.setItem('cartUuid', response.data.cartUuid);
                                            value["quantity"] = 1;
                                            restoreCart(value);
                                            history.push('/kasse');
                                            setLoading(false);
                                        }
                                    })
                                    break;
                            }
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                }).catch((error) => {
                    let status = error.response.status;
                    switch (status) {
                        case 404:
                            const newCartData = {};
                            postState(newCartData).then((response) => {
                                initNewCart(response.data.cartUuid);
                                localStorage.setItem('cartAge', 0);
                            }).then(() => {
                                history.push('/warenkorb');
                            }).catch((error) => {
                                console.log(error);
                            })
                            break;
                        case 401:
                            const data = {"userUuid": userUuid, "cartUuid": cartUuid}
                            postState(data).then((response) => {
                                let registrationIsExpired = response.data.isRegistrationExpired;
                                if(registrationIsExpired) {
                                    history.push('/registrierung-abgelaufen');
                                } else {
                                    console.log(response.data);
                                    history.push('/');
                                }
                            }).catch((error) => {
                                console.log(error);
                            });
                            break;
                        default:
                            console.log(error.response);
                    }
                });
                break;
            case 'three-ds-one-redirect':
                setThreeDsOneResultStep('third-step');
                const data = {"cartUuid": cartUuid}
                postState(data).then((response) => {
                    switch (response.data.cartContents.length) {
                        case 0:
                            localStorage.setItem('cartUuid', response.data.cartUuid);
                            history.push('/warenkorb');
                            break;
                        default:
                            setLoading(true);
                            voucher.forEach(value => {
                                if(value.id === response.data.cartContents[0].productId) {
                                    localStorage.setItem('cartUuid', response.data.cartUuid);
                                    value["quantity"] = 1;
                                    restoreCart(value);
                                    authDispatch({type: 'setUserUuid', payload: response.data.userUuid});
                                    authDispatch({type: 'setSuccessfulThreeDSAuth', payload: response.data.successfulThreeDSAuth});
                                    authDispatch({type: 'setCorrectIssuer', payload: response.data.isIssuerAllowed});
                                    authDispatch({type: 'setCardAllowedToPlay', payload: response.data.isCardAllowedToPlay});
                                }
                            })
                            break;
                    }
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                })
                break;
        }


    }, [task, cartUuid, userUuid]);
    return (
        <>
            <AuthGuard userUuidFromLocalState={userUuid} executePermission={executePermission} customRedirectUrl={null} />
            <div className={`layout-page-body CheckoutPaymentScreen ${loading ? 'is-loading' : ''}${threeDsOneResultStep === 'third-step' ? threeDsOneResultStep : ''}`}>
                <div className={'layout-page-body-inner'}>
                    <section className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-12 cell'}>
                                {
                                    auth.successfulThreeDSAuth ?
                                    <ProgressBar currentStep={4} /> :
                                    <ProgressBar currentStep={3} />
                                }
                            </div>
                        </div>
                    </section>
                    {loading ?
                        <section className={'grid-container'}>
                            <div className={'grid-x grid-padding-x'}>
                                <div className={'large-8 large-offset-2 cell'}>
                                    <h2 className={'text-center'}>Bitte warten&hellip;</h2>
                                    <Spinner loading={true} />
                                </div>
                            </div>
                        </section>
                    :
                    <>
                    <section className={'grid-container content-container-1'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-9 large-offset-2 cell'}>
                                <h2>Kostenlos eine 2-Faktor-Authentifizierung mit Ihrer HVB<br className={'break-large'} /> Mastercard<sup>®</sup> Kreditkarte testen</h2>
                            </div>
                        </div>
                    </section>
                    <section className={'threeds-checker-container'}>
                        <div className={'grid-container'}>
                            <div className={'grid-x grid-padding-x'}>
                                <div className={'large-8 large-offset-2 cell'}>
                                    <Adyen applyContentType={setContentType} cartUuid={cartUuid} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={'two-fa-how-to'}>
                        <div className={'grid-container'}>
                            <div className={'grid-x grid-padding-x'}>
                                <div className={'large-8 large-offset-2 medium-10 medium-offset-1 cell text-center'}>
                                    <h3>Für eine 2-Faktor-Authentifizierung haben Sie zwei Möglichkeiten:</h3>
                                </div>
                            </div>
                            <div className={'grid-x grid-margin-x'}>
                                <div className={'large-6 large-offset-0 medium-8 medium-offset-2 cell mobile-app'}>
                                    <div className={'grid-container'}>
                                        <div className={'grid-x grid-margin-x'}>
                                            <div className={'xlarge-2 xsmall-10 xsmall-offset-1 cell'}>
                                                <img src={require('../assets/images/icons/app-icon.png')} alt=""/>
                                            </div>
                                            <div className={'xlarge-8 xlarge-offset-0 xsmall-10 xsmall-offset-1 cell'}>
                                                <h4>HVB ID Check Mobile App</h4>
                                                <p>
                                                    Ihr Vorteil: biometrische Freigabe – ohne Passwort
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'large-6 large-offset-0 medium-8 medium-offset-2 cell ecode-sms'}>
                                    <div className={'grid-container'}>
                                        <div className={'grid-x grid-margin-x'}>
                                            <div className={'xlarge-2 xsmall-5 xsmall-offset-1 cell'}>
                                                <img src={require('../assets/images/icons/e-code.png')} alt=""/>
                                            </div>
                                            <div className={'xlarge-2 xsmall-5 xsmall-offset-0 cell'}>
                                                <img src={require('../assets/images/icons/sms-tan.png')} alt=""/>
                                            </div>
                                            <div className={'xlarge-6 xlarge-offset-0 xsmall-10 xsmall-offset-1 cell'}>
                                                <h4>Mit eCode und SMS-TAN</h4>
                                                <p>
                                                    eCode (5-stellig) und SMS-TAN eingeben
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={'grid-container two-fa-how-to-video'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'medium-8 medium-offset-2 cell text-center'}>
                                <h3>Mehr zur HVB ID Check Mobile App</h3>
                                <Video videoUrl={'https://video.hypovereinsbank.de/content/dam/hvb-media/produktvideos/banking-app/HVB-ID-Check-Mobile-App.mp4'}
                                       previewImage={require('../assets/images/video-preview.jpg')}
                                       muted={true}
                                       volume={1}
                                       width={'100%'}
                                       height={'auto'}
                                       playing={true}
                                       controls={true}
                                       stopOnUnmount={false}
                                />
                            </div>
                        </div>
                    </section>
                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default CheckoutPaymentScreen;
