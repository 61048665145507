import React, {useContext, useEffect, useState} from 'react';
import "./ConfirmScreen.scss";
import AuthGuard from "../components/Authentication/AuthGuard";
import {maximumCartAge} from "../services/products";
import {postState} from "../components/Api";
import {CartContext} from "../contexts/CartContext";
import {useHistory} from "react-router-dom";
import dateDiffCalc from "../utils/dateDiff";
import {useChangeEffect} from "../hooks/UseChangeEffect";
const ConfirmScreen = props => {
    const {initNewCart} = useContext(CartContext);
    const history = useHistory();
    const [cartAge, setCartAge] = useState();
    const [executePermission, setExecutePermission] = useState(false);
    useEffect(() => {
        setExecutePermission(true);
        dateDiffCalc();
        setCartAge(localStorage.getItem('cartAge'));
    }, [history.location]);
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission) {
            setExecutePermission(false);
        }
    })
    useChangeEffect({cartAge}, (v) => {
        if(v.cartAge > maximumCartAge) {
            const newCartData = {};
            postState(newCartData).then((response) => {
                initNewCart(response.data.cartUuid);
                localStorage.setItem('cartAge', 0);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setCartAge(localStorage.getItem('cartAge'));
            })
        }
    })
    return (
        <>
            <AuthGuard executePermission={executePermission} customRedirectUrl={null} />
            <div className={'layout-page-body ConfirmScreen'}>
                <div className={'layout-page-body-inner'}>
                    <section className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-8 large-offset-2 cell text-center'}>
                                <img src={require('../assets/images/icons/email.png')} alt="" />
                                <h2>Sie haben gerade eine E-Mail von uns erhalten. <br className={'break-large'} />
                                    Bitte klicken Sie auf den enthaltenen Link, um fortzufahren.
                                </h2>
                                <p>Sollten Sie keine E-Mail erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default ConfirmScreen;
