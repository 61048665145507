import React, {useContext, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {Field, Form, Formik, FormikProps, ErrorMessage } from "formik";
import {useAuthentication} from '../Authentication/AuthenticationProvider';
import * as Yup from "yup";
import {postPermission, postState} from "../Api";

const SignUpForm = props => {
    const history = useHistory();
    const [auth, authDispatch] = useAuthentication();
    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
            .required('Bitte befüllen Sie das Feld "E-Mail-Adresse".'),
        privacyPolicy: Yup.boolean()
            .oneOf([true], 'Bitte akzeptieren Sie die Teilnahmebedingungen und Datenschutzbestimmungen.')
    })
    const conditionalRedirect = (values, history, authDispatch, response) => { // redirect function
        let allowedToPlay = response.data.allowedToPlay;
        let emailIsVerified = response.data.emailIsVerified;
        let timeDifference = response.data.timeSinceInitialRegistration;
        let emailIsBlacklisted = response.data.isEmailBlacklisted;
        if(emailIsBlacklisted) { // if e-mail is blacklisted
            history.push('/teilnahme-nicht-moeglich');
        } else if(allowedToPlay && emailIsVerified){
            postPermission('/kasse', response.data.userUuid, response.data.cartUuid).then((response) => {
                const reason = response.data.reason;
                if(!response.data.hasPermission) {
                    switch (reason) {
                        case 'user_not_played_max_times_if_given':
                            history.push('/bereits-teilgenommen');
                        break;
                        default:
                            history.push('/');
                    }
                } else {
                    history.push('/kasse');
                }
            })
        } else if(!emailIsVerified && timeDifference <= 5) { // if e-mail is not verified and time difference between registration and e-mail send-out is max. 5 seconds
            history.push('/bestaetigen');
        } else if(!emailIsVerified && timeDifference >= 6) { // if e-mail is not verified and time difference between registration and e-mail send-out is min. 6 seconds
            history.push('/bereits-registriert');
        } else if(!allowedToPlay && emailIsVerified) { // if e-mail is verified and user is not allowed to play
            history.push('/bereits-teilgenommen');
        } else {
            history.push('/');
        }
    };

    const handleSubmit = React.useCallback((values, actions) => {
        const checkoutData = {
            "cartUuid": localStorage.getItem('cartUuid'),
            "email": values.email,
            "privacyPolicyIsAccepted": true
        }
        postState(checkoutData).then((response) => {
            conditionalRedirect(values, history, authDispatch, response);
            authDispatch({type: 'setUserUuid', payload: response.data.userUuid});
            authDispatch({type: 'setLoading', payload: false});
            localStorage.setItem('email', values.email);
        }).catch((err) => {
            console.log(err);
        });
        actions.setSubmitting(false);
    }, [history, authDispatch]);

    const openTerms = () => {
        document.querySelector('#terms').click();
    }
    const openPrivacy = () => {
        document.querySelector('#privacy').click();
    }

    return (
        <Formik initialValues={{email: '', privacyPolicy: false}} validationSchema={SignupSchema} onSubmit={handleSubmit}>
            {props => (
            <Form>
                <div>
                    <label htmlFor={'email'}>
                        <ErrorMessage name={'email'} component={'div'} className={'form-error is-invalid'} />
                        <Field type={'email'} name={'email'} placeholder={'E-Mail-Adresse (Pflichtfeld)'} />
                    </label>
                </div>
                <div className={'privacy-policy'}>
                    <label htmlFor={'privacyPolicy'}>
                        <ErrorMessage name={'privacyPolicy'} component={'div'} className={'form-error is-invalid'} />
                        <Field type={'checkbox'} id={'privacyPolicy'} name={'privacyPolicy'} />
                        <span className={'checkbox'} tabIndex={0} />
                        <span>Ja, ich habe die <strong><Link to={{javascript:void(0)}} onClick={openTerms} className={'underlined'}>Teilnahmebedingungen</Link></strong> und <strong><Link to={{javascript:void(0)}} onClick={openPrivacy} className={'underlined'}>Datenschutzbestimmungen</Link></strong> gelesen und akzeptiert.</span>
                    </label>
                </div>
                <div>
                    <button type={'submit'} className={'button btn-primary'}>Jetzt registrieren</button>
                </div>
            </Form>
        )}
        </Formik>
    );
};

export default SignUpForm;
