import React, {useContext, useEffect, useState} from "react";
import "./CheckoutRegisterScreen.scss";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import SignUpForm from "../components/SignUpForm/SignUpForm";
import Spinner from "../components/Spinner/Spinner";
import {maximumCartAge} from "../services/products";
import {postState} from "../components/Api";
import {CartContext} from "../contexts/CartContext";
import {useHistory} from "react-router-dom";
import dateDiffCalc from "../utils/dateDiff";
import {useChangeEffect} from "../hooks/UseChangeEffect";
import {useAuthentication} from "../components/Authentication/AuthenticationProvider";
import AuthGuard from "../components/Authentication/AuthGuard";

const CheckoutRegisterScreen = props => {
    const [auth, authDispatch] = useAuthentication();
    const {initNewCart} = useContext(CartContext);
    const history = useHistory();
    const [cartAge, setCartAge] = useState();
    const [retryPayment, setRetryPayment] = useState(false);
    const [executePermission, setExecutePermission] = useState(false);
    useEffect(() => {
        setExecutePermission(true);
        setRetryPayment(auth.retryPayment);
        dateDiffCalc();
        setCartAge(localStorage.getItem('cartAge'));
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
    }, [history.location]);
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission) {
            setExecutePermission(false);
        }
    })
    useChangeEffect({cartAge}, (v) => {
        if(v.cartAge > maximumCartAge) {
            const newCartData = {};
            postState(newCartData).then((response) => {
                initNewCart(response.data.cartUuid);
                localStorage.setItem('cartAge', 0);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setCartAge(localStorage.getItem('cartAge'));
            })
        }
    })
    useChangeEffect({retryPayment}, (v) => {
        let userUuid = auth.userUuid;
        let cartUuid = localStorage.getItem('cartUuid');
        switch (v.retryPayment) {
            case true:
                if(cartAge < maximumCartAge) {
                    history.push('/kasse?userUuid='+userUuid+'&cartUuid='+cartUuid);
                } else {
                    history.push('/warenkorb');
                }
                authDispatch({type: 'setRetryPayment', payload: false});
                setRetryPayment(false);
            break;
        }
    })
    return (
        <>
            <AuthGuard executePermission={executePermission} customRedirectUrl={null} />
            <div className={'layout-page-body CheckoutRegisterScreen'}>
                <div className={'layout-page-body-inner'}>
                    <section className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-12 cell'}>
                                <ProgressBar currentStep={2} />
                            </div>
                        </div>
                    </section>
                    <section className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-7 large-offset-2 cell'}>
                                <div className={'registration-form-text'}>
                                    <h2>Geben Sie Ihre E-Mail-Adresse ein</h2>
                                    <p>
                                        Nach Eingabe Ihrer E-Mail-Adresse erhalten Sie eine E-Mail von registrierung@2fa-test.de mit einem Link.<br />
                                        Bitte klicken Sie auf diesen Link, um Ihre Teilnahme zu bestätigen.
                                    </p>
                                    <p>
                                        Sie sind bereits registriert? Geben Sie hier Ihre E-Mail-Adresse ein und Sie gelangen direkt zur Eingabe Ihrer Kartendaten.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-7 large-offset-2 cell'}>
                                <Spinner loading={false} />
                                <SignUpForm />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default CheckoutRegisterScreen;
