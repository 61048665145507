import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import "./ProductItem.scss";
import {PopupboxManager} from "react-popupbox";
import {postAddProduct, postProductsInStock, postState} from "../Api";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import {useChangeEffect} from "../../hooks/UseChangeEffect";

const ProductItem = ({product}) => {
    const [auth, authDispatch] = useAuthentication();
    const { addProduct, cartItems, increase, removeProduct, initNewCart} = useContext(CartContext);
    const [inStockAmazon, setInStockAmazon] = useState();
    const [inStockZalando, setInStockZalando] = useState();
    const [inStockSportScheck, setInStockSportScheck] = useState();
    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }
    useChangeEffect({auth}, (v) => {
        if(v.auth.productsInStock !== null){
            setInStockAmazon(v.auth.productsInStock['Amazon']);
            setInStockSportScheck(v.auth.productsInStock['SportScheck']);
            setInStockZalando(v.auth.productsInStock['Zalando']);
        }
    }, []);
    const updateShoppingCart = () => {
        authDispatch({type: 'setLoading', payload: true});
        cartItems.forEach((v, k) => { // remove all products in frontend cart at first
            removeProduct(v);
        })
        const data = {"userUuid": null,"cartUuid": localStorage.getItem('cartUuid'),"product": product.id};
        postAddProduct(data).then((response) => { // add product to backend cart (existing products will be removed)
            addProduct(product); // add the product to frontend cart
            const currentDate = new Date();
            localStorage.setItem('cartLastAdded', currentDate);
        }).catch((error) => {
            let status = error.response.status;
            switch (status) {
                case 401:
                    const newCartData = {};
                    postState(newCartData).then((response) => {
                        initNewCart(response.data.cartUuid);
                        localStorage.setItem('cartAge', 0);
                        return response.data.cartUuid;
                    }).then((cartUuid) => {
                        const data = {"userUuid": null,"cartUuid": cartUuid,"product": product.id};
                        postAddProduct(data).then((response) => { // add product to backend cart (existing products will be removed)
                            addProduct(product); // add the product to frontend cart
                            const currentDate = new Date();
                            localStorage.setItem('cartLastAdded', currentDate);
                        }).catch((error) => {
                            console.log(error);
                        })
                    }).catch((error) => {
                        console.log(error);
                    })
                    break;
                default:
                    console.log(error.response);
                    break;
            }
        }).finally(() => {
            authDispatch({type: 'setLoading', payload: false});
        })
        PopupboxManager.close();
    }
    const lightBoxSnippet = () => {
        return (
            <>
            {
                !isInCart(product) &&
                <Link to={'/warenkorb'} onClick={updateShoppingCart} type={'button'} className={'button btn-primary'}>In den Warenkorb legen</Link>
            }
            {
                isInCart(product) &&
                <p>Das Produkt befindet sich im Warenkorb</p>
            }
            </>
        )
    }
    const lightboxOpen = () => {
        const content = (
            <>
                <div className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-right'}>
                            <span className={'lb-close'} onClick={PopupboxManager.close}>Schließen</span>
                        </div>
                    </div>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-6 cell product-image'}>
                            <img src={product.photo + '?v=' + product.id} alt={product.name} />
                        </div>
                        <div className={'large-5 cell'}>
                            <h4>{product.name}</h4>
                            <p>{product.description}</p>
                            <p><strong>Viel Spaß beim Einkaufen.</strong></p>
                            <div className={'cart-container'}>
                                {product.name.includes('Amazon') && inStockAmazon > 0 ?
                                    lightBoxSnippet()
                                    : product.name.includes('Amazon') && inStockAmazon === 0 ?
                                    <p>Das Produkt ist leider vergriffen</p>
                                    : null
                                }
                                {product.name.includes('Zalando') && inStockZalando > 0 ?
                                    lightBoxSnippet()
                                    : product.name.includes('Zalando') && inStockZalando === 0 ?
                                    <p>Das Produkt ist leider vergriffen</p>
                                    : null
                                }
                                {product.name.includes('SportScheck') && inStockSportScheck > 0 ?
                                    lightBoxSnippet()
                                    : product.name.includes('SportScheck') && inStockSportScheck === 0 ?
                                    <p>Das Produkt ist leider vergriffen</p>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        PopupboxManager.open({content, config: {fadeIn: true,fadeInSpeed: 200}})
    }

    return (
        <div className={`large-4 large-offset-0 medium-8 medium-offset-2 cell ${product.class} ${auth.loading ? 'hide-visibility' : null}`}>
            <div className={'product-card'}>
                <div className={'product-card-inner'}>
                    {/*<img src={product.photo + '?v=' + product.id} alt={product.name} onClick={lightboxOpen} />*/}
                    <img src={product.photo + '?v=' + product.id} alt={product.name} />
                </div>
                {/*<div className={'product-card-text'}>*/}
                {/*    <h3 className={'h4'}>{product.name}</h3>*/}
                {/*    <Link to={{javascript:void(0)}} onClick={lightboxOpen}><small>Details</small></Link>*/}
                {/*</div>*/}
                {product.name.includes('Amazon') && inStockAmazon > 0 ?
                    <Link to={'/warenkorb'} className={'button btn-primary'} onClick={updateShoppingCart}>Jetzt auswählen</Link>
                : product.name.includes('Amazon') && inStockAmazon === 0 ?
                    <p>Das Produkt ist leider vergriffen</p>
                : null
                }
                {product.name.includes('Zalando') && inStockZalando > 0 ?
                    <Link to={'/warenkorb'} className={'button btn-primary'} onClick={updateShoppingCart}>Jetzt auswählen</Link>
                : product.name.includes('Zalando') && inStockZalando === 0 ?
                    <p>Das Produkt ist leider vergriffen</p>
                : null
                }
                {product.name.includes('SportScheck') && inStockSportScheck > 0 ?
                    <Link to={'/warenkorb'} className={'button btn-primary'} onClick={updateShoppingCart}>Jetzt auswählen</Link>
                : product.name.includes('SportScheck') && inStockSportScheck === 0 ?
                    <p>Das Produkt ist leider vergriffen</p>
                : null
                }
            </div>
        </div>
     );
}

export default ProductItem;
