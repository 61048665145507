import React from "react";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import {useHistory} from "react-router-dom";
import dateDiffCalc from "../../utils/dateDiff";


const CardUnauthorizedToPlay = props => {
    const history = useHistory();
    const [auth, authDispatch] = useAuthentication();
    const retry = () => {
        dateDiffCalc();
        authDispatch({type: 'setCartAge', payload: localStorage.getItem('cartAge')});
        authDispatch({type: 'setRetryPayment', payload: true});
        history.push('/registrierung');
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
        authDispatch({type: 'setCorrectIssuer', payload: false});
    }
    return(
        <div className={'unauthorized-card'}>
            <img src={require('../../assets/images/credit-cards.png')} alt={''} />
            <h2>Das hat leider nicht geklappt. Ihre Kreditkarte wurde bereits getestet. Sie können jede HVB Kreditkarte nur einmal testen.</h2>
            <p>
                Bitte geben Sie <strong><a onClick={retry} className={'underlined'}>hier</a></strong> eine noch nicht getestete HVB Kartennummer ein.<br className={'break-large'} />
                Haben Sie keine HVB Kreditkarte, informieren Sie sich unter <a href={'https://hvb.de/mastercard'} target={'_blank'} className={'underlined'}>hvb.de/mastercard</a>.
            </p>
        </div>
    )
}

export default CardUnauthorizedToPlay;
