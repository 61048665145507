import React from 'react';
import './HomeScreen.scss';
import './CampaignHasEndedScreen.scss';
import HeroTeaser from "../components/HeroTeaser/HeroTeaser";
import Video from "../components/Video/Video";
import Store from "../components/Store";
import {Link} from "react-scroll";
const HeroTeaserContent = {
    Image: require('../assets/images/HeroTeaserHome_720.jpg'),
    Headline: '<span class="h1">2-Faktor-Test-Shop:</span> Probieren Sie die 2-Faktor-Authentifizierung<br class="break-medium"> Ihrer Kreditkarte aus: „Kaufen“ Sie kostenlos<br class="break-medium"> einen 5 € Gutschein Ihrer Wahl.',
    contentImage: require('../assets/images/credit-cards.png'),
}

const CampaignHasEndedScreen = props => {
    return (
        <>
        <div className={'layout-page-body HomeScreen CampaignHasEndedScreen'}>
            <div className={'layout-page-body-inner'}>
                <HeroTeaser
                    image={HeroTeaserContent.Image}
                    headline={HeroTeaserContent.Headline}
                    contentImage={HeroTeaserContent.contentImage}
                />
                 <section className={'grid-container section-80 choose-voucher'} id={'chooseVoucher'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell'}>
                            <h4>Für mehr Sicherheit: Online-Einkäufe mit HVB Mastercard<sup>®</sup> Kreditkarte müssen jetzt häufiger mit 2 Faktoren freigegeben werden. Entweder mit eCode und SMS-TAN oder mit der HVB ID Check Mobile App. Sind Sie bereit dafür? Hier können Sie es ausprobieren und kostenlos einen 5 € Gutschein „kaufen“.</h4>
                        </div>
                    </div>
                </section>
                <Store />
                <section className={'two-fa-how-to'}>
                    <div className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-8 large-offset-2 medium-10 medium-offset-1 cell text-center'}>
                                <h3 className={'h4'}>Ist Ihre Karte schon bereit für die 2-Faktor-Authentifizierung?<br className={'break-large'} /> Es funktioniert entweder über die HVB ID Check Mobile App oder mit eCode und SMS-TAN.</h3>
                            </div>
                        </div>
                        <div className={'grid-x grid-margin-x'}>
                            <div className={'large-6 large-offset-0 medium-8 medium-offset-2 cell mobile-app'}>
                                <div className={'grid-container'}>
                                    <div className={'grid-x grid-margin-x'}>
                                        <div className={'xlarge-2 xsmall-10 xsmall-offset-1 cell'}>
                                            <img src={require('../assets/images/icons/app-icon.png')} alt=""/>
                                        </div>
                                        <div className={'xlarge-8 xlarge-offset-0 xsmall-10 xsmall-offset-1 cell'}>
                                            <h4>HVB ID Check Mobile App</h4>
                                            <p>
                                                Ihr Vorteil: biometrische Freigabe – ohne Passwort<br className={'break-large'} />
                                                &nbsp;
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'grid-x grid-margin-x'}>
                                        <div className={'xsmall-10 xsmall-offset-1 cell'}>
                                            <h4>So installieren Sie Ihre HVB ID Check Mobile App:</h4>
                                            <ol>
                                                <li>Fordern Sie Ihren Aktivierungscode im Online Banking an oder kontaktieren Sie den HVB Kartenservice. Kurz darauf kommt der Aktivierungscode per SMS.</li>
                                                <li>Laden Sie die App herunter und starten Sie die Installation.</li>
                                                <li>Geben Sie den Aktivierungscode und den eCode ein.</li>
                                                <li>Geben Sie Ihre biometrischen Daten frei.</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className={'grid-x grid-margin-x'}>
                                        <div className={'xsmall-10 xsmall-offset-1 cell'}>
                                            <p>Nun sind Sie bereit zur Freigabe von Online-Zahlungen per App ohne Passwort!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'large-6 large-offset-0 medium-8 medium-offset-2 cell ecode-sms'}>
                                <div className={'grid-container'}>
                                    <div className={'grid-x grid-margin-x'}>
                                        <div className={'xlarge-2 xsmall-5 xsmall-offset-1 cell'}>
                                            <img src={require('../assets/images/icons/e-code.png')} alt=""/>
                                        </div>
                                        <div className={'xlarge-2 xsmall-5 xsmall-offset-0 cell'}>
                                            <img src={require('../assets/images/icons/sms-tan.png')} alt=""/>
                                        </div>
                                        <div className={'xlarge-6 xlarge-offset-0 xsmall-10 xsmall-offset-1 cell'}>
                                            <h4>eCode und SMS-TAN</h4>
                                            <p>
                                                eCode (5-stellig) eingeben und<br />
                                                SMS-TAN eingeben
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'grid-x grid-margin-x'}>
                                        <div className={'xsmall-10 xsmall-offset-1 cell'}>
                                            <h4>So bekommen Sie Ihren eCode:</h4>
                                            <ul>
                                                <li>Im HVB Online Banking können Sie diesen selbst einrichten und jederzeit ändern.</li>
                                                <li>Falls Sie nicht zum Online Banking angemeldet sind, haben Sie Ihren eCode bereits per Post erhalten. Oder Sie wenden sich an den HVB Kartenservice.</li>
                                                <li>Ihr persönlicher eCode gilt für alle Ihre HVB Karten.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'grid-container two-fa-how-to-video'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 large-offset-0 medium-8 medium-offset-2 cell text-center'}>
                            <h3>So funktioniert’s:</h3>
                            <Video videoUrl={'https://video.hypovereinsbank.de/content/dam/hvb-media/produktvideos/banking-app/HVB-ID-Check-Mobile-App.mp4'}
                                    previewImage={require('../assets/images/video-preview.jpg')}
                                    muted={true}
                                    volume={1}
                                    width={'100%'}
                                    height={'auto'}
                                    playing={true}
                                    controls={true}
                                    stopOnUnmount={false}
                            />
                        </div>
                    </div>
                </section>
                <section className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 large-offset-0 medium-8 medium-offset-2 cell text-center'} />
                    </div>
                </section>
                <section className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 large-offset-0 medium-8 medium-offset-2 cell text-center'}>
                            <Link className={'button btn-primary'}>zur Gutscheinauswahl</Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </>
    );
}

export default CampaignHasEndedScreen;
