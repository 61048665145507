import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import IncorrectIssuer from "./IncorrectIssuer";
import CardUnauthorizedToPlay from "./CardUnauthorizedToPlay";
import GeneralErrorMessage from "./GeneralErrorMessage";
import dateDiffCalc from "../../utils/dateDiff";

const ErrorMessage = props => {
    const [auth, authDispatch] = useAuthentication();
    const history = useHistory();
    const retry = () => {
        dateDiffCalc();
        authDispatch({type: 'setCartAge', payload: localStorage.getItem('cartAge')});
        authDispatch({type: 'setRetryPayment', payload: true});
        history.push('/registrierung');
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
        authDispatch({type: 'setCorrectIssuer', payload: false});
    }
    return(
        <div className='error-msg text-center'>
            {
            props.additionalInformation === 'issuer not allowed' || auth.correctIssuer === false ?
                <IncorrectIssuer />
            : props.additionalInformation === 'card not allowed to play' || auth.cardAlreadyPlayed ?
                <CardUnauthorizedToPlay />
            : props.additionalInformation === null && !auth.cardAlreadyPlayed ?
            <div className={'threeds-auth-not-successfull'}>
                <img src={require('../../assets/images/icons/error.png')} alt={''} />
                <h2>Das hat leider nicht geklappt.</h2>
                <p>Ihre Authentifizierung wurde nicht bestätigt oder abgebrochen. Bitte versuchen Sie es <strong><Link to={{javascript:void(0)}} onClick={retry} className={'underlined'}>hier</Link></strong> erneut.</p>
                <img src={require('../../assets/images/icons/app-icon.png')} alt=""/>
                <h4>Noch keine HVB ID Check Mobile App?</h4>
                <p>
                    Ganz ohne eCode merken und eintippen – installieren Sie jetzt die HVB ID Check Mobile App und geben Sie Ihre Online-Zahlungen ganz einfach per Biometrie frei!
                    Unsere HVB App finden Sie auch hier: <strong><a href={'https://play.google.com/store/apps/details?id=de.unicredit.idcheckmobile'} target={'_blank'} className={'underlined'}>Google Play Store</a></strong> und <strong><a href={'https://apps.apple.com/de/app/apple-store/id1479018918'} target={'_blank'} className={'underlined'}>iOS App Store</a></strong>
                </p>
            </div>
            : <GeneralErrorMessage errorMessage={props.additionalInformation}  />
            }
        </div>
    )
}

export default ErrorMessage;
