import React from 'react';

import ProductsGrid from './ProductsGrid';

const Store = () => {

    return (
        <ProductsGrid/>
     );
}

export default Store;
