import React from "react";
import "./ProgressBar.scss";
const icons = {
    "previous": {
        "step1": require('./icons/previous/step-1.png'),
        "step2": require('./icons/previous/step-2.png'),
        "step3": require('./icons/previous/step-3.png'),
        "step1Success": require('./icons/previous/step-1-success.png'),
        "step2Success": require('./icons/previous/step-2-success.png'),
        "step3Success": require('./icons/previous/step-3-success.png'),
    },
    "current": {
        "step1": require('./icons/current/step-1.png'),
        "step2": require('./icons/current/step-2.png'),
        "step3": require('./icons/current/step-3.png'),
        "step4": require('./icons/current/step-4.png'),
    },
    "next": {
        "step2": require('./icons/next/step-2.png'),
        "step3": require('./icons/next/step-3.png'),
        "step4": require('./icons/next/step-4.png'),
    }
}

const ProgressBar = props => {

    return (
        <div className={'ProgressBar current-step-' + props.currentStep}>
            <ul className={'reset-list'}>
                <li className={'step-1'}>
                    <div className={'step-content text-center'}>
                        {
                            props.currentStep === 1 ?
                            <img src={icons.current.step1} alt="" /> :
                            props.currentStep === 2 ?
                            <img src={icons.previous.step1} alt="" /> :
                            props.currentStep === 3 ?
                            <img src={icons.previous.step1} alt="" /> :
                            props.currentStep === 4 ?
                            <img src={icons.previous.step1Success} alt="" /> :
                            null
                        }
                        <p className={'h5'}>Warenkorb</p>
                    </div>
                </li>
                <li className={'step-spacer'} />
                <li className={'step-2'}>
                    <div className={'step-content text-center'}>
                        {
                            props.currentStep === 1 ?
                            <img src={icons.next.step2} alt="" /> :
                            props.currentStep === 2 ?
                            <img src={icons.current.step2} alt="" /> :
                            props.currentStep === 3 ?
                            <img src={icons.previous.step2} alt="" /> :
                            props.currentStep === 4 ?
                            <img src={icons.previous.step2Success} alt="" /> :
                            null
                        }
                        <p className={'h5'}>Anmeldung</p>
                    </div>
                </li>
                <li className={'step-spacer'} />
                <li className={'step-3'}>
                    <div className={'step-content text-center'}>
                        {
                            props.currentStep === 1 ?
                            <img src={icons.next.step3} alt="" /> :
                            props.currentStep === 2 ?
                            <img src={icons.next.step3} alt="" /> :
                            props.currentStep === 3 ?
                            <img src={icons.current.step3} alt="" /> :
                            props.currentStep === 4 ?
                            <img src={icons.previous.step3Success} alt="" /> :
                            null
                        }
                        <p className={'h5'}>2-Faktor-Test</p>
                    </div>
                </li>
                <li className={'step-spacer'} />
                <li className={'step-4'}>
                    <div className={'step-content text-center'}>
                        {
                            props.currentStep === 1 ?
                            <img src={icons.next.step4} alt="" /> :
                            props.currentStep === 2 ?
                            <img src={icons.next.step4} alt="" /> :
                            props.currentStep === 3 ?
                            <img src={icons.next.step4} alt="" /> :
                            props.currentStep === 4 ?
                            <img src={icons.current.step4} alt="" /> :
                            null
                        }
                        <p className={'h5'}>Fertig</p>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ProgressBar;
