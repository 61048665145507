import React, {useContext, useEffect} from 'react';

import CartProducts from './CartProducts';
import { CartContext } from '../../contexts/CartContext';
import "./Cart.scss";
import { Link } from 'react-router-dom';
import Spinner from "../Spinner/Spinner";
import {useAuthentication} from "../Authentication/AuthenticationProvider";

const Cart = () => {

    const { total, cartItems, itemCount, clearCart, checkout, handleCheckout, clearCheckout } = useContext(CartContext);
    const [auth, authDispatch] = useAuthentication();

    return (
        <div className={'grid-container Cart'}>
            <div className={'grid-x grid-padding-x'}>
                {
                    cartItems.length > 0 ?
                        <>
                        <CartProducts/>
                        <div className={'large-9 large-offset-3 cell text-right'}>
                            <div className={'cart-separator'} />
                            <Link to={'/registrierung'} className={'button btn-primary'} type={'button'}>Gutschein kostenfrei sichern</Link>
                        </div>
                        </>:
                        <>
                        <div className={`large-12 empty-cart text-center ${!auth.loading ? 'hide' : ''}`}>
                            <h2 className={'text-center'}>Bitte warten&hellip;</h2>
                            <Spinner loading={auth.loading} />
                        </div>
                        <div className={`large-12 empty-cart text-center ${auth.loading ? 'hide' : ''}`}>
                            <p>
                                Der Warenkorb ist leer
                            </p>
                            <div>
                                <Link to={'/?gutscheine'} className={'button btn-primary'}>zur Gutscheinauswahl</Link>
                            </div>
                        </div>
                        </>
                }
            </div>
        </div>
     );
}

export default Cart;
