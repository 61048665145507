import React, {useContext, useEffect, useState} from "react";
import "./ShoppingCartScreen.scss";
import Cart from "../components/Cart";
import AuthGuard from "../components/Authentication/AuthGuard";
import HeroTeaser from "../components/HeroTeaser/HeroTeaser";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import {postState} from "../components/Api";
import {CartContext} from "../contexts/CartContext";
import {maximumCartAge} from "../services/products";
import {useHistory} from "react-router-dom";
import dateDiffCalc from "../utils/dateDiff";
import {useChangeEffect} from "../hooks/UseChangeEffect";
import {useAuthentication} from "../components/Authentication/AuthenticationProvider";
const HeroTeaserContent = {
    Image: require('../assets/images/HeroTeaserHome_720.jpg'),
    Headline: 'Ihr Warenkorb',
    contentImage: require('../assets/images/credit-cards.png'),
}
const ShoppingCartScreen = props => {
    const [loading, setLoading] = useState();
    const [auth, authDispatch] = useAuthentication();
    const {initNewCart} = useContext(CartContext);
    const history = useHistory();
    const [cartAge, setCartAge] = useState();
    const [renewCart, setRenewCart] = useState();
    const [executePermission, setExecutePermission] = useState(false);
    useEffect(() => {
        setExecutePermission(true);
        dateDiffCalc();
        authDispatch({type: 'setCartAge', payload: localStorage.getItem('cartAge')});
        setCartAge(auth.cartAge);
        setRenewCart(auth.renewCart);
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
    }, [history.location]);
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission) {
            setExecutePermission(false);
        }
    })
    useChangeEffect({cartAge, renewCart}, (v) => {
        if(v.cartAge > maximumCartAge || v.renewCart) {
            const newCartData = {};
            postState(newCartData).then((response) => {
                initNewCart(response.data.cartUuid);
                localStorage.setItem('cartAge', 0);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setCartAge(localStorage.getItem('cartAge'));
            })
        }
    })

    return (
        <>
        <AuthGuard executePermission={executePermission} customRedirectUrl={null} />
        <div className={'layout-page-body ShoppingCartScreen'}>
            <div className={'layout-page-body-inner'}>
                <HeroTeaser
                    image={HeroTeaserContent.Image}
                    headline={HeroTeaserContent.Headline}
                    contentImage={HeroTeaserContent.contentImage}
                />
                <section className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell'}>
                            <ProgressBar currentStep={1} />
                        </div>
                    </div>
                </section>
                <section className={'products-in-shopping-cart'}>
                    <Cart />
                </section>
                <section className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-8 large-offset-2 cell'}>
                            <h2>In 3 einfachen Schritten:</h2>
                            <ol className={'how-to-list'}>
                                <li><span>E-Mail-Adresse bestätigen</span></li>
                                <li><span>Kartendaten eingeben</span></li>
                                <li><span>2-Faktor-Authentifizierung durchführen – Gutschein kostenlos erhalten</span></li>
                            </ol>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </>
    );
}

export default ShoppingCartScreen;
