import React, {useContext, useEffect, useState} from 'react';
import "./AlreadyRegisteredScreen.scss";
import AuthGuard from "../components/Authentication/AuthGuard";
import {maximumCartAge} from "../services/products";
import {postState} from "../components/Api";
import {CartContext} from "../contexts/CartContext";
import {useHistory} from "react-router-dom";
import dateDiffCalc from "../utils/dateDiff";
import {useChangeEffect} from "../hooks/UseChangeEffect";

const AlreadyRegisteredScreen = props => {
    const {initNewCart} = useContext(CartContext);
    const history = useHistory();
    const [cartAge, setCartAge] = useState();
    const [executePermission, setExecutePermission] = useState(false);
    useEffect(() => {
        setExecutePermission(true);
        dateDiffCalc();
        setCartAge(localStorage.getItem('cartAge'));
    }, [history.location]);
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission) {
            setExecutePermission(false);
        }
    })
    useChangeEffect({cartAge}, (v) => {
        if(v.cartAge > maximumCartAge) {
            const newCartData = {};
            postState(newCartData).then((response) => {
                initNewCart(response.data.cartUuid);
                localStorage.setItem('cartAge', 0);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setCartAge(localStorage.getItem('cartAge'));
            })
        }
    })

    return (
        <>
            <AuthGuard executePermission={executePermission} customRedirectUrl={null} />
            <div className={'layout-page-body AlreadyRegisteredScreen'}>
                <div className={'layout-page-body-inner'}>
                    <section className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-8 large-offset-2 cell'}>
                                <h1 className={'h2'}>Ihre E-Mail-Adresse ist bereits registriert.</h1>
                                <p>
                                    Bitte prüfen Sie Ihr E-Mail-Postfach und bestätigen Sie Ihre Registrierung.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default AlreadyRegisteredScreen;
