export const voucher = [
    {
        id: 1,
        name: "5 € Amazon Gutschein",
        price: 0,
        photo: require('../assets/images/amazon-card.png'),
        description: "Mit einem Geschenkgutschein von Amazon.de haben Sie die große Auswahl aus dem gesamten Sortiment von Amazon.de. Damit finden Sie garantiert das Richtige für Sie und Ihre Liebsten.",
        class: "amazon"
    },
    {
        id: 2,
        name: "5 € SportScheck Gutschein",
        price: 0,
        photo: require('../assets/images/sportscheck-card.png'),
        description: "Das SportScheck-Konzept: mehr Spaß am Sport mit großer Auswahl, Qualität, Beratung und Service!",
        class: "sportscheck"
    },
    {
        id: 3,
        name: "5 € Zalando Gutschein",
        price: 0,
        photo: require('../assets/images/zalando-card.png'),
        description: "Zalando, Europas führende Online-Modeplattform bietet für jeden etwas – von Schuhen über Kleidung und Beauty bis hin zu Accessoires und Sportkleidung beliebter High-Street-Marken, Premium-Designer und Independent-Labels.",
        class: "zalando"
    },
];
export const maximumCartAge = 60; // timeframe in which items are reserved (minutes)
