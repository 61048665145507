import axios_src from 'axios';

import {API_PATH} from '../config/networking';

export const axios = axios_src.create();

axios.interceptors.request.use(config => {
    config.baseURL = API_PATH;
    config.timeout = 30000;
    return config;
});

export const parseError = error => {
    if (error.response) {
        return {
            data: error.response.data,
            status: error.response.status
        }
    }
    if (error.request) {
        return {
            data: error.request.statusText,
            status: error.request.status
        }
    }
    return {
        data: error.message,
        status: -1
    }
};
