import React from 'react';

const AuthenticationProvider = props => {
    const {children} = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
        <AuthenticationContext.Provider value={[state, dispatch]}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;

const AuthenticationContext = React.createContext([]);

const initialState = {
    allowedToPlay: false, // is user allowed to play the game
    base64Image: null, // scratchcard image
    cardAllowedToPlay: false, // has credit card already played
    correctIssuer: false, // credit card issuer is correct
    emailIsVerified: false, // e-mail address is confirmed (opt in confirmed)
    gameSessionUuid: null, // game session id
    hasPermissionToAccess: false, // user has permission to access the page
    hasWon: false, // user has won the game
    isNewsletterSubscriber: false, // user is newsletter subscriber
    lastGamePeriod: false, // the last period of the game has begun
    loading: false, // loading state for displaying the spinner
    privacyPolicyIsAccepted: false, // user accepted our privacy and policy terms
    scratchcardUuid: null, // scratchcard id
    scratched: false, // information if the scratchcard has been scratched
    gameSessionUuidForGameInit: null, // exactly the same gameSessionUuid. it is set before the scratchcard init
    timeSinceInitialRegistration: null, // we need this state to handle the "already registered and e-mail not yet confirmed" case.
    userUuid: null, // user id
    voucherCode: null, // voucher code
    gameBack: false,
    successfulThreeDSAuth: null,
    cartAge: null,
    retryPayment: false,
    renewCart: false,
    productsInStock: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'setGameSessionUuid':
            return {...state, gameSessionUuid: action.payload};
        case 'setUserUuid':
            return {...state, userUuid: action.payload};
        case 'setAllowedToPlay':
            return {...state, allowedToPlay: action.payload};
        case 'setIsNewsletterSubscriber':
            return {...state, isNewsletterSubscriber: action.payload};
        case 'setEmailIsVerified':
            return {...state, emailIsVerified: action.payload};
        case 'setPrivacyPolicyIsAccepted':
            return {...state, privacyPolicyIsAccepted: action.payload};
        case 'setTimeSinceInitialRegistration':
            return {...state, timeSinceInitialRegistration: action.payload};
        case 'setScratchcardUuid':
            return {...state, scratchcardUuid: action.payload};
        case 'setHasWon':
            return {...state, hasWon: action.payload};
        case 'setBase64Image':
            return {...state, base64Image: action.payload};
        case 'setScratched':
            return {...state, scratched: action.payload};
        case 'setLoading':
            return {...state, loading: action.payload};
        case 'setCorrectIssuer':
            return {...state, correctIssuer: action.payload};
        case 'setCardAllowedToPlay':
            return {...state, cardAllowedToPlay: action.payload};
        case 'setHasPermissionToAccess':
            return {...state, hasPermissionToAccess: action.payload};
        case 'setVoucherCode':
            return {...state, voucherCode: action.payload};
        case 'setLastGamePeriod':
            return {...state, lastGamePeriod: action.payload};
        case 'setGameSessionUuidForGameInit':
            return {...state, gameSessionUuidForGameInit: action.payload};
        case 'setGameBack':
            return {...state, gameBack: action.payload};
        case 'setSuccessfulThreeDSAuth':
            return {...state, successfulThreeDSAuth: action.payload};
        case 'setCartAge':
            return {...state, cartAge: action.payload};
        case 'setRetryPayment':
            return {...state, retryPayment: action.payload};
        case 'setRenewCart':
            return {...state, renewCart: action.payload};
        case 'setProductsInStock':
            return {...state, productsInStock: action.payload};
        case 'clear':
            return {...initialState};
        default:
            return state;
    }
};

export const useAuthentication = () => React.useContext(AuthenticationContext);
