import React, { createContext, useState } from 'react';
import { voucher } from '../services/products';
export const ProductsContext = createContext()

const ProductsContextProvider = ({children}) => {

    const [products] = useState(voucher);

    return (
        <ProductsContext.Provider value={{products}} >
            { children }
        </ProductsContext.Provider>
     );
}

export default ProductsContextProvider;
