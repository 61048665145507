import React from "react";
import {Link} from "react-router-dom";
import IncorrectIssuer from "./IncorrectIssuer";
import CardUnauthorizedToPlay from "./CardUnauthorizedToPlay";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import GeneralErrorMessage from "./GeneralErrorMessage";

const SuccessMessage = props => {
    const [auth, authDispatch] = useAuthentication();
    const resetThreeDsAuth = () => {
        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
        authDispatch({type: 'setCorrectIssuer', payload: false});
    }
    return(
        <div className={'success-msg text-center'}>
            {
            props.additionalInformation === 'issuer not allowed' || auth.correctIssuer === false ?
                <IncorrectIssuer />
            : props.additionalInformation === 'card not allowed to play' || !auth.cardAllowedToPlay ?
                <CardUnauthorizedToPlay />
            : props.additionalInformation === null && auth.cardAllowedToPlay ?
                <div className={'threeds-auth-successfull'}>
                    <img src={require('../../assets/images/icons/mc_idcheck_vrt_pos.png')} alt={''} />
                    <h2>Glückwunsch!</h2>
                    <p>Sie haben erfolgreich eine 2-Faktor-Authentifizierung durchgeführt. Ihr Gutschein wurde soeben an Ihre angegebene E-Mail-Adresse gesendet.</p>
                </div>
            : <GeneralErrorMessage errorMessage={props.additionalInformation}  />
            }
        </div>
    )
}

export default SuccessMessage;
