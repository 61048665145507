import React from "react";
import {PopupboxContainer} from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import "./Lightbox.scss";

const Lightbox = props => {
    return (
        <div className={'Lightbox'}>
            <PopupboxContainer />
        </div>
    );
}

export default Lightbox;
