import React, {useContext, useEffect, useState} from 'react';
import "./Adyen.scss";
import DropInButton from "./DropInButton";
import SuccessMessage from "./SuccesMessage";
import ErrorMessage from "./ErrorMessage";
import {useAuthentication} from "../Authentication/AuthenticationProvider";
import {post3DsAuth, postState, postUseCard, postPermission, postAddProduct, postRemoveProduct} from "../Api";
import {useChangeEffect} from "../../hooks/UseChangeEffect";
import {CartContext} from "../../contexts/CartContext";
import {useMounted} from "../../hooks/Mounted";
import {maximumCartAge} from "../../services/products";

const apiHost = process.env.REACT_APP_API_URL;
const adyenOriginKey = process.env.REACT_APP_ADYEN_ORIGIN_KEY;
const adyenEnvironment = process.env.REACT_APP_ADYEN_ENVIRONMENT;

const waitFor = (ms) => new Promise(r => setTimeout(r, ms));
const checkIfExternalJsIsLoaded = async(callback) => { // check if external adyen script is loaded
    await waitFor(50);
    if(window._a$checkoutShopperUrl !== undefined) {
        console.log('external adyen script loaded');
        return callback();
    } else {
        console.log('external adyen script not loaded');
        return checkIfExternalJsIsLoaded(callback);
    }
};
const checkIfCustomJsIsLoaded = async(callback) => { // check if our custom adyen script is loaded
    await waitFor(50);
    if(window.initAdyen !== undefined) {
        console.log('custom adyen script loaded');
        return callback();
    } else {
        console.log('custom adyen script not loaded');
        return checkIfCustomJsIsLoaded(callback);
    }
};
const loadExternalAdyen = (callback) => { // create elements and load external adyen script and css
    let script = document.createElement("script");
    let styles = document.createElement('link');
    script.async = true;
    script.src = "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.4.0/adyen.js";
    styles.rel = "stylesheet";
    styles.href = "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.4.0/adyen.css";
    document.head.appendChild(script);
    document.head.appendChild(styles);
    return checkIfExternalJsIsLoaded(callback);
};
const loadCustomAdyen = (callback) => { // create element and load our custom adyen script
    const script = document.createElement("script");
    script.async = true;
    script.src = process.env.PUBLIC_URL + "/adyen.js";
    document.head.appendChild(script);
    return checkIfCustomJsIsLoaded(callback);
};

const Adyen = props => {
    const {applyContentType} = props;
    const adyenRef = React.useRef();
    const [status, setStatus] = React.useState();
    const [additionalInformation, setadditionalInformation] = React.useState(null); // providing more information for error and success handling
    const [adyenDropIn, setAdyenDropIn] = React.useState();
    const [auth, authDispatch] = useAuthentication();
    const [adyenStep, setAdyenStep] = useState();
    const { handleCheckout, addProduct, initNewCart } = useContext(CartContext);
    useChangeEffect({adyenStep}, (v) => {
        if(v.adyenStep < 3 && auth.successfulThreeDSAuth !== null) { // reset auth state
            authDispatch({type: 'setSuccessfulThreeDSAuth', payload: null});
        }
    }, [adyenStep]);

    useChangeEffect({status}, (v) => {
        switch (v.status) {
            case 'success':
                checkout();
                break;
            case 'error':
                break;
        }
    }, [status])

    useEffect(() => { // set status for 3ds one transaction
        switch (auth.successfulThreeDSAuth) {
            case true:
                const data = {
                    "userUuid": auth.userUuid,
                    "cartUuid": localStorage.getItem('cartUuid')
                }
                postPermission('/api/cart/use-card', data.userUuid, data.cartUuid).then((response) => {
                    switch (response.data.hasPermission) {
                        case true:
                            if (auth.cardAllowedToPlay && auth.correctIssuer) {
                                setStatus('success');
                            } else {
                                setStatus('error');
                            }
                            break;
                        case false:
                            authDispatch({type: 'setLoading', payload: true});
                            const cart = JSON.parse(localStorage.getItem('cart'))[0];
                            const productID = localStorage.getItem('cart').split('"id":')[1].split(',')[0];
                            const oldCartUuid = localStorage.getItem('cartUuid');
                            postState({}).then((response) => {
                                initNewCart(response.data.cartUuid);
                                localStorage.setItem('cartAge', 0);
                                return response.data.cartUuid;
                            }).then((cartUuid) => {
                                const checkoutData = {
                                    "cartUuid": cartUuid,
                                    "email": localStorage.getItem('email'),
                                    "privacyPolicyIsAccepted": true
                                }
                                postState(checkoutData).then((response) => {
                                    const newData = {
                                        "userUuid": response.data.userUuid,
                                        "cartUuid": response.data.cartUuid,
                                        "product": parseInt(productID)
                                    }
                                    postAddProduct(newData).then(() => {
                                        addProduct(cart); // add the product to frontend cart
                                        const currentDate = new Date();
                                        localStorage.setItem('cartLastAdded', currentDate);
                                    }).then(() => {
                                        const oldData = {
                                            "userUuid": auth.userUuid,
                                            "cartUuid": oldCartUuid,
                                            "product": parseInt(productID)
                                        }
                                        postRemoveProduct(oldData).then((response) => {
                                            console.log('empty cart', response.data);
                                        })
                                    }).catch((error) => {
                                        console.log(error.response);
                                    })
                                }).finally(() => {
                                    setStatus('error');
                                }).catch((err) => {
                                    console.log(err);
                                });
                            }).catch((error) => {
                                console.log(error);
                            })
                    }
                }).catch((error) => {
                    console.log(error.response);
                })
                break;
            case false:
                setStatus('error');
                break;
            default:
                break;
        }
    }, [auth.successfulThreeDSAuth, auth.cardAllowedToPlay, auth.correctIssuer])

    const handleSuccess = React.useCallback((resultCode, isIssuerAllowed, isCardAllowedToPlay) => { // adyen success callback handling
        applyContentType('result');
        setAdyenStep(3);
        const data = {
            "userUuid": auth.userUuid,
            "cartUuid": localStorage.getItem('cartUuid')
        };
        postPermission('/api/cart/use-card', data.userUuid, data.cartUuid).then((response) => {
            switch (response.data.hasPermission) {
                case true:
                    if(!isIssuerAllowed) {
                        setadditionalInformation('issuer not allowed');
                        setStatus('error');
                        authDispatch({type: 'setCorrectIssuer', payload: false});
                        authDispatch({type: 'setCardAllowedToPlay', payload: false});
                    } else if(!isCardAllowedToPlay) {
                        setadditionalInformation('card not allowed to play');
                        setStatus('error');
                        authDispatch({type: 'setCorrectIssuer', payload: true});
                        authDispatch({type: 'setCardAllowedToPlay', payload: false});
                    } else {
                        authDispatch({type: 'setCorrectIssuer', payload: true});
                        authDispatch({type: 'setCardAllowedToPlay', payload: true});
                        authDispatch({type: 'setSuccessfulThreeDSAuth', payload: true});
                        setStatus('success');
                    }
                    break;
                case false:
                    authDispatch({type: 'setLoading', payload: true});
                    const cart = JSON.parse(localStorage.getItem('cart'))[0];
                    const productID = localStorage.getItem('cart').split('"id":')[1].split(',')[0];
                    const oldCartUuid = localStorage.getItem('cartUuid');
                    postState({}).then((response) => {
                        initNewCart(response.data.cartUuid);
                        localStorage.setItem('cartAge', 0);
                        return response.data.cartUuid;
                    }).then((cartUuid) => {
                        const checkoutData = {
                            "cartUuid": cartUuid,
                            "email": localStorage.getItem('email'),
                            "privacyPolicyIsAccepted": true
                        }
                        postState(checkoutData).then((response) => {
                            const newData = {
                                "userUuid": response.data.userUuid,
                                "cartUuid": response.data.cartUuid,
                                "product": parseInt(productID)
                            }

                            postAddProduct(newData).then(() => {
                                addProduct(cart); // add the product to frontend cart
                                const currentDate = new Date();
                                localStorage.setItem('cartLastAdded', currentDate);
                            }).then(() => {
                                const oldData = {
                                    "userUuid": auth.userUuid,
                                    "cartUuid": oldCartUuid,
                                    "product": parseInt(productID)
                                }
                                postRemoveProduct(oldData).then((response) => {
                                    console.log('empty cart', response.data);
                                })
                            }).catch((error) => {
                                console.log(error.response);
                            })
                        }).finally(() => {
                            authDispatch({type: 'setLoading', payload: false});
                            setadditionalInformation('card not allowed to play');
                            authDispatch({type: 'setCardAllowedToPlay', payload: false});
                            authDispatch({type: 'setCorrectIssuer', payload: true});
                            setStatus('error');
                        }).catch((err) => {
                            console.log(err);
                        });
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        }).catch((error) => {
            console.log(error.response);
        })
    }, [authDispatch]);
    const handleError = React.useCallback((resultCode, isIssuerAllowed, isCardAllowedToPlay, ErrorMessage) => { // adyen error callback handling
        console.log('handle error');
        applyContentType('result');
        setAdyenStep(3);
        if(ErrorMessage){
            console.log('1 ', resultCode, ErrorMessage);
            setadditionalInformation(ErrorMessage);
            setStatus('error');
            authDispatch({type: 'setLoading', payload: false});
        }else if(!isIssuerAllowed) {
            console.log('2 ', resultCode, ErrorMessage);
            setadditionalInformation('issuer not allowed');
            setStatus('error');
            authDispatch({type: 'setCorrectIssuer', payload: false});
            authDispatch({type: 'setCardAllowedToPlay', payload: false});
        } else if(!isCardAllowedToPlay) {
            console.log('3 ', resultCode, ErrorMessage);
            setadditionalInformation('card not allowed to play');
            setStatus('error');
            authDispatch({type: 'setCorrectIssuer', payload: true});
            authDispatch({type: 'setCardAllowedToPlay', payload: false});
        }else{
            console.log('4 ', resultCode, ErrorMessage);
            setStatus('error');
            authDispatch({type: 'setCorrectIssuer', payload: true});
            authDispatch({type: 'setCardAllowedToPlay', payload: true});
        }
    }, [authDispatch]);
    const handleDetails = React.useCallback(() => { // adyen callback handling for 2nd adyen step
        console.log('handle details')
        applyContentType('details');
        setAdyenStep(2);
        authDispatch({type: 'setLoading', payload: false});
    }, [authDispatch, applyContentType])
    const handleSubmit = React.useCallback(() => { // adyen form submit handler for custom button
        console.log('handle submit', window.dropin);
        try {
            adyenDropIn.submit();
            authDispatch({type: 'setLoading', payload: true});
        } catch(e) {
            console.warn(e);
        }
    }, [authDispatch, adyenDropIn]);

    const checkout = () => {
        const data = {
            "userUuid": auth.userUuid,
            "cartUuid": localStorage.getItem('cartUuid')
        }
        handleCheckout(data);
        const newCartData = {};
        postState(newCartData).then((response) => {
            initNewCart(response.data.cartUuid);
        })
    }
    const checkIfAppIsReadyForAdyen = async(callback) => {
        await waitFor(100);
        if(props.cartUuid !== undefined && document.querySelector('#dropin') !== null){
            return callback();
        } else {
            checkIfAppIsReadyForAdyen(callback);
        }
    }
    React.useEffect(() => {
        checkIfAppIsReadyForAdyen(() => {
            loadExternalAdyen(() => {
                loadCustomAdyen(() => { // load custom script after external script is loaded
                    const checkCartUuid = async() => {
                        await waitFor(10);
                        if(props.cartUuid !== null){
                            post3DsAuth(props.cartUuid).then((response) => {
                                return response.data.threeDSAuthUuid;
                            }).then((threeDSAuthUuid) => { // initialize adyen dropin implementation
                                const dropIn = window.initAdyen(adyenRef.current, handleSuccess, handleError, handleDetails, threeDSAuthUuid, adyenOriginKey, apiHost, adyenEnvironment);
                                setAdyenDropIn(dropIn);
                                applyContentType('form');
                                setAdyenStep(1);
                            }).catch((error) => {
                                console.log(error);
                            });
                        } else {
                            checkCartUuid();
                        }
                    }
                    checkCartUuid();
                })
            });
        })
    }, []);

    return (
        <>
            {auth.successfulThreeDSAuth !== null ? // three ds redirect auth method
            <div>
                {auth.successfulThreeDSAuth ? (
                    <SuccessMessage additionalInformation={additionalInformation} />
                ) : null}
                {!auth.successfulThreeDSAuth ? (
                    <ErrorMessage additionalInformation={additionalInformation} />
                ): null}
            </div>
                :  // three ds two auth method
            <div ref={adyenRef} id="dropin">
                {adyenDropIn ? <DropInButton onClick={handleSubmit}/> : null}
                {status === 'success' ? (
                    <SuccessMessage additionalInformation={additionalInformation} />
                ) : null}
                {status === 'error' ? (
                    <ErrorMessage additionalInformation={additionalInformation} />
                ): null}
            </div>
            }
        </>
    );
};

export default Adyen;
