import React, {useContext, useEffect, useState} from 'react';
import "./AlreadyParticipatedScreen.scss";
import AuthGuard from "../components/Authentication/AuthGuard";
import {maximumCartAge} from "../services/products";
import {postState} from "../components/Api";
import {CartContext} from "../contexts/CartContext";
import {useHistory} from "react-router-dom";
import dateDiffCalc from "../utils/dateDiff";
import {useChangeEffect} from "../hooks/UseChangeEffect";

const AlreadyParticipatedScreen = props => {
    const {initNewCart} = useContext(CartContext);
    const history = useHistory();
    const [cartAge, setCartAge] = useState();
    const [executePermission, setExecutePermission] = useState(false);
    useEffect(() => {
        setExecutePermission(true);
        dateDiffCalc();
        setCartAge(localStorage.getItem('cartAge'));
    }, [history.location]);
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission) {
            setExecutePermission(false);
        }
    })
    useChangeEffect({cartAge}, (v) => {
        if(v.cartAge > maximumCartAge) {
            const newCartData = {};
            postState(newCartData).then((response) => {
                initNewCart(response.data.cartUuid);
                localStorage.setItem('cartAge', 0);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setCartAge(localStorage.getItem('cartAge'));
            })
        }
    })

    return (
        <>
        <AuthGuard executePermission={executePermission} customRedirectUrl={null} />
        <div className={'layout-page-body AlreadyParticipatedScreen'}>
            <div className={'layout-page-body-inner'}>
                <section className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-8 large-offset-2 cell text-center'}>
                            <img src={require('../assets/images/icons/email.png')} alt="" />
                            <h2>Das hat leider nicht geklappt. <br className={'break-large'} />
                                Die von Ihnen eingegebene E-Mail-Adresse wurde bereits verwendet.</h2>
                            <img src={require('../assets/images/icons/app-icon.png')} alt=""/>
                            <h4>HVB ID Check Mobile App</h4>
                            <p>
                                Ganz ohne eCode merken und eintippen – installieren Sie jetzt die HVB ID Check Mobile App und geben Sie Ihre Online-Zahlungen ganz einfach frei!
                                Unsere HVB App finden Sie auch hier: <strong><a href={'https://play.google.com/store/apps/details?id=de.unicredit.idcheckmobile'} target={'_blank'} className={'underlined'}>Google Play Store</a></strong> und <strong><a href={'https://apps.apple.com/de/app/apple-store/id1479018918'} target={'_blank'} className={'underlined'}>iOS App Store</a></strong>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </>
    );
};

export default AlreadyParticipatedScreen;
