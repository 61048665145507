import React from "react";
import ReactPlayer from "react-player/lazy";
import "./Video.scss";

const Video = props => {
    return (
        <div className={'Video'}>
            <ReactPlayer url={props.videoUrl}
                         light={props.previewImage}
                         muted={props.muted}
                         volume={props.volume}
                         width={props.width}
                         height={props.height}
                         playing={props.playing}
                         controls={props.controls}
                         stopOnUnmount={props.stopOnUnmount}
            />
        </div>
    )
}
export default Video;
