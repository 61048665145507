import React, {useContext, useEffect} from 'react';
import { CartContext } from '../../contexts/CartContext';
import "./CartItem.scss";
import {Link} from "react-router-dom";
import {postRemoveProduct} from "../Api";

const CartItem = ({product}) => {

    const { increase, decrease, removeProduct} = useContext(CartContext);
    const updateShoppingCart = () => {
        const data = {"userUuid": null,"cartUuid": localStorage.getItem('cartUuid'),"product": product.id};
        postRemoveProduct(data).then((response) => {
            localStorage.removeItem('cartLastAdded');
        })
        removeProduct(product);
    }

    return (
        <>
        <div className={'grid-container CartItem'}>
            <div className={'grid-x grid-margin-x'}>
                <div className={'large-3 cell'}>
                    <div className={'product-image'}>
                        <img
                            alt={product.name}
                            src={product.photo} />
                    </div>
                </div>
                <div className={'large-6 cell'}>
                    <p className={'h4'}>{product.name}</p>
                    {/*<p>{product.description}</p>*/}
                    {/*<p><strong>Viel Spaß beim Einkaufen.</strong></p>*/}
                </div>
                <div className={'large-3 cell text-right'}>
                    <p className={'h4'}>Menge: {product.quantity}</p>
                    {
                        product.quantity > 0 &&
                        <Link to={{javascript:void(0)}}
                            onClick={updateShoppingCart}>
                            Entfernen
                        </Link>
                    }
                </div>
            </div>
        </div>
        </>
     );
}

export default CartItem;
