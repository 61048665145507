import React, {useContext, useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { CartContext } from '../../contexts/CartContext';
import "./Header.scss";
import {CartIcon, HvbIcon} from '../../icons';
import {useChangeEffect} from "../../hooks/UseChangeEffect";
const cart = require('../../assets/images/shopping-cart.png');

const Header = () => {
    const {itemCount} = useContext(CartContext);
    const [indicator, setIndicator] = useState('');
    useEffect(() => {
        if(itemCount > 0) {
            setIndicator('cart-indicator');
        }
    }, [itemCount])
    useChangeEffect({itemCount}, (v) => {
        if(v.itemCount > 0) {
            setIndicator('cart-indicator');
        } else {
            setIndicator('');
        }
    })

    return (
        <header className={'layout-page-head'}>
            <div className={'layout-page-head-inner'}>
                <Link className={'brand-logo'} to={'/'}>
                    <strong>Ein Service für Kunden der</strong>
                    <HvbIcon width={192} />
                </Link>
                <Link className={'shopping-cart ' + indicator} to={'/warenkorb'}><img src={cart} width={23} alt={''} /></Link>
            </div>
        </header>
    );
}

export default Header;
