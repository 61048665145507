import React from 'react';
import ReactDOM from 'react-dom';
import reactime from 'reactime';
import App from './App';


const rootContainer = document.getElementById('root');
ReactDOM.render(
    <App />, rootContainer
);

// reactime doesn't like hooks
// reactime(rootContainer);
