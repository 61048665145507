import {axios} from "../utils/axios";
const apiHost = process.env.REACT_APP_API_URL;

const postPermission = (route, userUuid, cartUuid) => {
    return axios.post(apiHost + '/api/permission', {
        "route": route,
        "userUuid": userUuid,
        "cartUuid": cartUuid
    })
}
const postState = (data) => {
    return axios.post(apiHost + '/api/state', {
        "cartUuid": data.cartUuid !== null ? data.cartUuid : null,
        "email": data.email !== null ? data.email : null,
        "userUuid": data.userUuid !== null ? data.userUuid : null,
        "privacyPolicyIsAccepted": data.privacyPolicyIsAccepted !== null ? data.privacyPolicyIsAccepted : false
    })
}
const postAddProduct = (data) => {
    return axios.post(apiHost + '/api/cart/add-product', {
        "userUuid": data.userUuid,
        "cartUuid": data.cartUuid,
        "product": data.product
    })
}
const postRemoveProduct = (data) => {
    return axios.post(apiHost + '/api/cart/remove-product', {
        "userUuid": data.userUuid,
        "cartUuid": data.cartUuid,
        "product": data.product
    })
}
const postStateEmail = (email) => {
    return axios.post(apiHost + '/api/state', {
        "email": email,
        "privacyPolicyIsAccepted": true
    })
}
const postConfirmEmail = (userUuid, cartUuid) => {
    return axios.post(apiHost + '/api/user/confirm-email', {
        "userUuid": userUuid,
        "cartUuid": cartUuid,
        "confirmEmail": true
    })
}
const postConfirmSubscription = (userUuid) => {
    return axios.post(apiHost + '/api/newsletter/confirm-subscription', {
        "userUuid": userUuid,
        "confirmSubscription": true
    })
}
const post3DsAuth = (cartUuid) => {
    return axios.post(apiHost + '/api/3ds-auth', {
        "cartUuid": cartUuid
    })
}
const postScratchcard = (userUuid, gameSessionUuid) => {
    return axios.post(apiHost + '/api/scratchcard', {
        "gameSessionUuid": gameSessionUuid,
        "userUuid": userUuid
    })
}
const postScratchcardScratched = (userUuid, gameSessionUuid, scratchcardUuid, event, uuid) => {
    return axios.post(apiHost + '/api/scratchcard/scratch', {
        "scratchcardUuid": event === 'click' ? scratchcardUuid : uuid,
        "gameSessionUuid": gameSessionUuid,
        "userUuid": userUuid,
        "scratched": true
    })
}
const postPrize = (userUuid, gameSessionUuid, scratchcardUuid) => {
    return axios.post(apiHost + '/api/prize', {
        "userUuid": userUuid,
        "gameSessionUuid": gameSessionUuid,
        "scratchcardUuid": scratchcardUuid
    })
}
const postNewsletterUnsubscribe = (userUuid) => {
    return axios.post(apiHost + '/api/newsletter/unsubscribe', {
        "userUuid": userUuid,
        "unsubscribe": true
    })
}
const postNewsletterSubscribe = (userUuid) => {
    return axios.post(apiHost + '/api/newsletter/subscribe', {
        "userUuid": userUuid,
        "subscribe": true
    })
}
const postPayBill = (data) => {
    return axios.post(apiHost + '/api/cart/pay-bill', {
        "userUuid": data.userUuid,
        "cartUuid": data.cartUuid,
        "billPayed": true
    })
}
const postProductsInStock = () => {
    return axios.post(apiHost + '/api/stock/products', {})
}
const postUseCard = (userUuid, cartUuid) => {
    return axios.post(apiHost + '/api/cart/use-card', {
        "userUuid": userUuid,
        "cartUuid": cartUuid
    })
}

const postReminder = (value) => {
    return axios.post(apiHost + '/api/reminder', {
        "param": "reminder",
        "val": value
    })
}
export {
    postPermission,
    postState,
    postAddProduct,
    postRemoveProduct,
    postStateEmail,
    postConfirmEmail,
    postConfirmSubscription,
    post3DsAuth,
    postScratchcard,
    postScratchcardScratched,
    postPrize,
    postNewsletterUnsubscribe,
    postNewsletterSubscribe,
    postPayBill,
    postProductsInStock,
    postUseCard,
    postReminder
}
