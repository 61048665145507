import React, {useEffect} from "react";
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import "./HeroTeaser.scss";
import {useMounted} from "../../hooks/Mounted";
import {useChangeEffect} from "../../hooks/UseChangeEffect";
const HeroTeaser = props => {
    const mounted = useMounted();

    useEffect(() => {
        Events.scrollEvent.register('begin', function(to, element) {
        });

        Events.scrollEvent.register('end', function(to, element) {
        });
        scrollSpy.update();

    }, [Events.scrollEvent, scrollSpy]);

    return (
        <div className={'HeroTeaser'} style={{'backgroundImage': 'url('+props.image+')'}}>
            <div className={'hero-teaser-inner'}>
                <div className={'hero-teaser-content'}>
                    <div className={'grid-container'}>
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-12 cell'}>
                                <h1 dangerouslySetInnerHTML={{__html: props.headline}} />
                                {props.subline ?
                                    <h2 className={'h5'} dangerouslySetInnerHTML={{__html: props.subline}} />
                                    : null}
                                {props.contentImage ?
                                    <img src={props.contentImage} alt="" />
                                : null}
                                {props.buttontext && props.scrollTarget ?
                                    <Link className={'button btn-secondary'} to={props.scrollTarget} spy={true} smooth={true} offset={50} duration={500}>
                                        {props.buttontext}
                                    </Link>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroTeaser;
