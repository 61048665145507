import React from 'react';

export const useMounted = () => {
    const [mounted] = React.useState({current: true});
    React.useEffect(() => {
        mounted.current = true;
        return () => {mounted.current = false};
    }, [mounted]);
    return mounted;
};
