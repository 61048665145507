import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useAuthentication} from './AuthenticationProvider';
import {postPermission} from "../Api"
import {useChangeEffect} from "../../hooks/UseChangeEffect";
const AuthGuard = props => {
    /**
     * The AuthGuard can do anything you want.
     * You could clear the localStorage, show a Dialog or make a request against the API.
     */

    const [auth, authDispatch] = useAuthentication();
    const history = useHistory();
    const route = history.location.pathname;
    const cartUuid = localStorage.getItem('cartUuid');
    const [executePermission, setExecutePermission] = useState();
    useChangeEffect({executePermission}, (v) => {
        if(v.executePermission){
            authDispatch({type: 'setLoading', payload: true});
            postPermission(route, props.userUuidFromLocalState !== undefined ? props.userUuidFromLocalState : auth.userUuid, cartUuid).then((response) => {
                const reason = response.data.reason;
                if(!response.data.hasPermission){
                    switch (reason) {
                        case 'checkout_incomplete':
                            authDispatch({type: 'setRenewCart', payload: true});
                            history.push('/warenkorb');
                            authDispatch({type: 'setRenewCart', payload: false});
                            break;
                        default:
                            history.push(props.customRedirectUrl === null ? response.data.redirectUrl : props.customRedirectUrl);
                    }
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                authDispatch({type: 'setLoading', payload: false});
            })
        }
    })

    useEffect(() => {
        setExecutePermission(props.executePermission);
    }, [props.executePermission]);

    return null;
};

export default AuthGuard;
