import React from 'react';
import _isEqual from 'lodash/isEqual';

export const useChangeEffect = (value, handleChange, handleCleanup) => {
    const prevValue = React.useRef(value);
    React.useEffect(() => {
        const changed = hasChanged(value, prevValue.current);
        prevValue.current = value;
        if(changed) {
            handleChange(value);
            if(isFunction(handleCleanup)) return handleCleanup;
        }
    }, [value, handleChange, handleCleanup]);
};

const hasChanged = (...args) => !_isEqual(...args);

const isFunction = value => value && {}.toString.call(value) === '[object Function]';
