import React, { useEffect } from 'react';

import HomeScreen from '../screens/HomeScreen';
import ConfirmScreen from "../screens/ConfirmScreen";
import AlreadyParticipatedScreen from "../screens/AlreadyParticipatedScreen";
import AlreadyRegisteredScreen from "../screens/AlreadyRegisteredScreen";
import UnauthorizedScreen from "../screens/UnauthorizedScreen";
import RegistrationExpiredScreen from "../screens/RegistrationExpiredScreen";
import ShoppingCartScreen from "../screens/ShoppingCartScreen";
import CheckoutRegisterScreen from "../screens/CheckoutRegisterScreen";
import CheckoutPaymentScreen from "../screens/CheckoutPaymentScreen";
import CampaignHasEndedScreen from '../screens/CampaignHasEndedScreen';
const date = new Date();
const campaignHasEnded = date.getFullYear() != 2022;
export const routes = [
    {
        path: '/',
        component: !campaignHasEnded ? <HomeScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/warenkorb',
        component: !campaignHasEnded ? <ShoppingCartScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/registrierung',
        component: !campaignHasEnded ? <CheckoutRegisterScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/bestaetigen',
        component: !campaignHasEnded ? <ConfirmScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/bereits-registriert',
        component: !campaignHasEnded ? <AlreadyRegisteredScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/teilnahme-nicht-moeglich',
        component: !campaignHasEnded ? <UnauthorizedScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/registrierung-abgelaufen',
        component: !campaignHasEnded ? <RegistrationExpiredScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/kasse',
        component: !campaignHasEnded ? <CheckoutPaymentScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
    {
        path: '/bereits-teilgenommen',
        component: !campaignHasEnded ? <AlreadyParticipatedScreen /> : <CampaignHasEndedScreen />,
        routes: [
            // ... optional: nested routes
        ]
    },
];
