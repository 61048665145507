const dateDiffCalc = () => {
    if (localStorage.getItem('cartLastAdded') !== null) {
        const msPerMinute = 1000 * 60;
        const oldDate = new Date(localStorage.getItem('cartLastAdded'));
        const currentDate = new Date();
        const utc1 = Date.UTC(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), oldDate.getHours(), oldDate.getMinutes());
        const utc2 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes());
        return localStorage.setItem('cartAge', Math.floor((utc2 - utc1) / msPerMinute));
    }
}
export default dateDiffCalc;
