import React, { useEffect } from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";
import {PopupboxManager} from "react-popupbox";
import { HvbIcon } from '../../icons';
const lightboxImprint = () => {
    const content = (
        <>
            <div className={'imprint'}>
                <div className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-right'}>
                            <span className={'lb-close'} onClick={PopupboxManager.close}>Schließen</span>
                        </div>
                    </div>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-left'}>
                            <h2>Impressum</h2>
                            <h3>ANGABEN GEMÄß § 5 TMG</h3>
                            <p>
                                K+R GmbH<br />
                                Ludwig-Erhard-Straße 6<br />
                                65760 Eschborn
                            </p>
                            <h3>VERTRETEN DURCH</h3>
                            <p>
                                Michael Roschanski<br />
                                Andreas M. Denk<br />
                                Simone Zwecker
                            </p>
                            <h3>KONTAKT</h3>
                            <p>
                                Telefon:&nbsp;+49 6196 93063-0<br />
                                Telefax:&nbsp;+49 6196 93063-45<br />
                                E-Mail:&nbsp;&nbsp;<a href={'mailto:info@krgroup.de'} className={'underlined'}>info@krgroup.de</a>
                            </p>
                            <h3>REGISTEREINTRAG</h3>
                            <p>
                                Eintragung im Handelsregister.<br />
                                Registergericht: Frankfurt am Main<br />
                                Registernummer: HRB 40140
                            </p>
                            <h3>UMSATZSTEUER-ID</h3>
                            <p>
                                Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE161720540<br />
                                Verantwortlich für den Inhalt nach § 55 Abs.2
                            </p>
                            <h3>RSTV</h3>
                            <p>
                                Michael Roschanski<br />
                                Andreas M. Denk<br />
                                Simone Zwecker
                            </p>
                            <p>
                                K+R GmbH<br />
                                Ludwig-Erhard-Straße 6<br />
                                65760 Eschborn
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    PopupboxManager.open({content, config: {fadeIn: true,fadeInSpeed: 200}})
}
const lightboxPrivacyPolicy = () => {
    const content = (
        <>
            <div className={'privacy-policy'}>
                <div className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-right'}>
                            <span className={'lb-close'} onClick={PopupboxManager.close}>Schließen</span>
                        </div>
                    </div>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-left'}>
                            <h2>Datenschutzbestimmungen</h2>
                            <h3>I.&nbsp;&nbsp;&nbsp;Name und Anschrift des Verantwortlichen</h3>
                            <p>
                                Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (im Folgenden „DSGVO“) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
                            </p>
                            <p>
                                K+R GmbH<br />
                                Ludwig-Erhard-Straße 6<br />
                                65760 Eschborn
                            </p>
                            <p>
                                Tel. 06196 9306330<br />
                                Fax 06196 93063 45<br />
                                <a href={'mailto:info@krgroup.de'} className={'underlined'}>info@krgroup.de</a>
                            </p>
                            <p>
                                (im Folgenden „Verantwortlicher“ oder „K+R“)
                            </p>
                            <h3>II.&nbsp;&nbsp;&nbsp;Name und Anschrift des Datenschutzbeauftragten</h3>
                            <h4>Der Datenschutzbeauftragte des Verantwortlichen ist:</h4>
                            <p>
                                Weißmann Datenschutz GmbH<br />
                                RA Benedikt Rudolph<br />
                                Anschrift:<br />
                                Bosenheimer Str. 10, 55543 Bad Kreuznach<br />
                                Tel.: +49 (0) 30 2359 92171<br />
                                Fax: +49 (0) 30 2359 92178<br />
                                E-Mail: Datenschutz@kanzlei-weissmann.de
                            </p>
                            <h3>III.&nbsp;&nbsp;&nbsp;Allgemeines zur Datenverarbeitung</h3>
                            <h4>1.&nbsp;&nbsp;Umfang der Verarbeitung personenbezogener Daten</h4>
                            <p>
                                Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies für die Veranstaltung dieser Aktion, zur Bereitstellung einer funktionsfähigen Website, unserer Inhalte und zur Erbringung unserer Leistungen und etwaiger damit verbundener Nebenleistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
                            </p>
                            <h4>2.&nbsp;&nbsp;Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
                            <p>
                                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient diese in Verbindung mit Artikel 6 Absatz 1 Satz 1 Buchstabe a und Artikel 7 DSGVO als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
                            </p>
                            <p>
                                Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, ist Artikel 6 Absatz 1 Satz 1 Buchstabe b DSGVO Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Beispielsweise kann dies die Verarbeitung von Daten zur Durchführung einer solchen Aktion sein.
                            </p>
                            <p>
                                Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person das erstgenannte Interesse nicht, so dient uns Artikel 6 Absatz 1 Satz 1 Buchstabe f DSGVO als Rechtsgrundlage für die Verarbeitung.
                            </p>
                            <h4>3.&nbsp;&nbsp;Datenlöschung, Einschränkung der Datenverarbeitung und Speicherdauer</h4>
                            <p>
                                Soweit erforderlich, verarbeiten wir Ihre Daten für die Dauer dieser Aktion und der anschließenden Abwicklung und Gutscheinversendung.
                            </p>
                            <p>
                                Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald die Gewinnabwicklung erfolgt ist. Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                            </p>
                            <p>
                                Die Verarbeitung der Daten wird eingeschränkt, wenn
                            </p>
                            <p>
                                a) die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
                            </p>
                            <p>
                                b) die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;
                            </p>
                            <p>
                                c) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder
                            </p>
                            <p>
                                d) die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
                            </p>
                            <h4>4.&nbsp;&nbsp;Schutz personenbezogener Daten</h4>
                            <p>
                                Wir haben auf Basis des gesetzlich geforderten risikoorientierten Ansatzes technische und organisatorische Maßnahmen umgesetzt, um personenbezogene Daten gegen zufällige oder vorsätzliche Manipulationen, gegen Verlust, Zerstörung oder den Zugriff Unberechtigter zu schützen. Unsere Sicherheitsmaßnahmen werden unter anderem entsprechend der technologischen Entwicklung fortlaufend kontrolliert und verbessert.
                            </p>
                            <h3>IV.&nbsp;&nbsp;&nbsp;Bereitstellung der Website</h3>
                            <h3>Datenverarbeitung im Zusammenhang mit der Nutzung unserer Website und der Teilnahme an der Aktion</h3>
                            <h4>1.&nbsp;&nbsp;Webauftritt und Logfiles</h4>
                            <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                            <p>
                                Bei jeder Nutzung unseres Webauftritts werden Daten zwischen dem Client (z. B. Ihrem Web-Browser) und dem Server (unser Webauftritt) ausgetauscht. Dabei werden automatisch personenbezogene Daten von uns erhoben und auf unserem Server gespeichert. Auf unserem Server werden hierfür die nachfolgenden Informationen gespeichert (Datum und Uhrzeit des Zugriffs, Typ und Version Ihres Internet-Browsers, Typ und Version des Betriebssystems, volle IP-Adresse des Nutzers, Internet-Service-Provider des Nutzers, aufgerufene Seiten des Webauftritts, Datenmenge der angeforderten Inhalte unserer Website, die zuvor besuchte Seite / Referrer URL)
                            </p>
                            <h5>Zweck</h5>
                            <p>
                                Diese Informationen werden von uns ausschließlich für die nachfolgenden Zwecke genutzt: Erstellung von Nutzungsstatistiken und Statistiken des IT-Betriebs, Optimierung unserer Inhalte, Sicherstellung des Betriebs oder der Betriebssicherheit, Analyse bei Verdacht auf eine rechtswidrige Nutzung unserer Website.
                            </p>
                            <h5>Rechtsgrundlage</h5>
                            <p>
                                Rechtsgrundlage für die vorübergehende Speicherung dieser Daten und der zugehörigen Logfiles ist Artikel 6 Absatz 1 Satz 1 Buchstabe f DSGVO, wobei unser berechtigtes Interesse in der Erfüllung der oben genannten Zwecke liegt.
                            </p>
                            <h5>Dauer der Speicherung</h5>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zweckes nicht mehr erforderlich sind.
                            </p>
                            <h5>Widerruf, Widerspruch und Ende der Verarbeitung</h5>
                            <p>
                                Der Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles kann effektiv durch Verlassen der Internetseite widersprochen werden. Soweit personenbezogene Daten bereits gespeichert sind, richten Sie Ihren etwaigen Widerspruch bitte an die eingangs genannten Kontaktdaten. Soweit die Daten für die Sicherung und den Betrieb der Website zwingend erforderlich sind besteht keine Widerspruchsmöglichkeit.
                            </p>
                            <h4>2.&nbsp;&nbsp;Technisch notwendige Cookies</h4>
                            <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                            <p>
                                Cookies sind Informationsdateien, die beim Abruf unserer Website auf Ihrem verwendeten Endgerät abgelegt werden, insbesondere, um diese Informationen zu einem späteren Zeitpunkt wieder nutzen zu können. Cookies speichern Informationen, wie beispielsweise Ihre Besuchsdauer auf unserer Website oder auch Ihre dort getätigten Eingaben.
                            </p>
                            <p>
                                Bei jeder Nutzung unseres Webauftritts werden die nachfolgenden Informationen in Cookies abgelegt: Session Cookies, damit die Seiteninhalte in einer Weise angeboten werden können, die einem modernen Nutzererlebnis entspricht. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
                            </p>
                            <h5>Zweck</h5>
                            <p>
                                Zweck dieser Datenverarbeitung ist es, die Betriebsfähigkeit unseres Webauftritts zu gewährleisten.
                            </p>
                            <h5>Rechtsgrundlage</h5>
                            <p>
                                Da wir die Cookies verwenden, um einen ordnungsgemäßen Betrieb unserer Website zu gewährleisten, stützen wir die Datenverarbeitung auf unser berechtigtes Interesse gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe f DSGVO.
                            </p>
                            <h5>Dauer der Speicherung</h5>
                            <p>
                                Die Daten werden mit Löschung eines Cookies gelöscht. Das kann im Cookie hinterlegt sein oder durch den User aktiv über den Browser veranlasst werden. Die handelsüblichen Browser bieten diese Möglichkeit, Cookies seitens des Users zu löschen.
                            </p>
                            <h5>Widerruf, Widerspruch und Ende der Verarbeitung</h5>
                            <p>
                                Der Erfassung der Daten durch Cookies und der Speicherung der Daten kann effektiv durch Verlassen des Webangebots widersprochen werden. Soweit personenbezogene Daten bereits gespeichert sind, löschen Sie mit der dafür vorgesehenen Browser-Funktionalität bitte die Cookies und richten Sie etwaige darüberhinausgehende Widersprüche bitte an die eingangs genannten Kontaktdaten.
                            </p>
                            <h4>3.&nbsp;&nbsp;Technisch nicht notwendige Cookies</h4>
                            <p>Technisch nicht notwendige Cookies werden von uns nicht verwendet.</p>
                            <h3>V.&nbsp;&nbsp;&nbsp;Authentifizierungsprozess</h3>
                            <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
                            <p>
                                Im Rahmen der Nutzung der Plattform erfolgt eine ID-Check Authentifizierung – bei der kein Zahlungsvorgang stattfindet. In diesem Zusammenhang gibt der Teilnehmer seine E-Mail-Adresse sowie seine Kreditkartennummer und Ablaufdatum ein.
                            </p>
                            <p>
                                Die vom Teilnehmer eingegebenen Kreditkartendaten werden, mit Ausnahme der BIN-Nummer, nicht selbst vom Verantwortlichen verarbeitet. Die Daten fließen direkt an den Dienstleister Adyen N.V., Simon Carmiggeltstraat 6-50, 1011 DJ Amsterdam, Niederlande. Der Verantwortliche oder andere Dienstleister des Verantwortlichen erlangen keine Kenntnis von den vollständigen Kartendaten.
                            </p>
                            <p>
                                Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, einen Gutschein aus einer Auswahl zu wählen, und diesen per E-Mail zugesendet zu bekommen. Die E-Mail-Adresse wird dazu vom Teilnehmer an uns übermittelt und bei uns gespeichert.
                            </p>
                            <p>
                                Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten werden im Rahmen der Nutzung der Plattform erhoben:
                            </p>
                            <ol>
                                <li><i className={'underlined'}>Nutzungsdaten:</i> Zeitpunkt, Tag, Dauer der Nutzung, ausgeführte Interaktionen, Websites, von denen das System des Nutzers auf unsere Internetseite gelangt, Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
                                <li><i className={'underlined'}>Nutzerdaten:</i> Informationen über den Browsertyp und die verwendete Version, Betriebssystem des Nutzers, Internet-Service-Provider des Nutzers, IP-Adresse des Nutzers</li>
                                <li><i className={'underlined'}>Registrierungsdaten:</i> E-Mailadresse, Kreditkartennummer inkl. BIN, Ablaufdatum der Kreditkarte</li>
                                <li><i className={'underlined'}>Gutscheinabruf:</i> Zeitpunkt</li>
                            </ol>
                            <p>
                                Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt
                            </p>
                            <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
                            <p>
                                Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                            </p>
                            <h4>Zweck der Datenverarbeitung</h4>
                            <p>
                                Eine Registrierung des Nutzers ist für die Teilnahme an der Aktion erforderlich. Wir verwenden die erhobenen personenbezogenen Daten zu den folgenden Zwecken:
                            </p>
                            <p>
                                Bereitstellung der Kampagne und Kommunikation, u. a. zur elektronischen Versendung der Gutscheine.
                            </p>
                            <h4>Weitergabe der Daten an Dritte</h4>
                            <p>
                                Die erhobenen personenbezogenen Daten werden im Rahmen der Gutscheinversendung ggf. an folgende beteiligte Unternehmen weitergegeben:
                            </p>
                            <ul>
                                <li>CAMAO Darmstadt GmbH, Europaplatz 5, 64293 Darmstadt, Deutschland</li>
                                <li>Adyen N.V., Simon Carmiggeltstraat 6-50, 1011 DJ Amsterdam, Niederlande</li>
                                <li>Dienstleister, die in unserem Namen und Auftrag handeln.</li>
                            </ul>
                            <p>
                                Wir verpflichten diese Dienstleister vertraglich dazu, personenbezogene Daten ausschließlich gemäß unseren Anweisungen zu verarbeiten und soweit dies zur Erbringung von Dienstleistungen oder zur Einhaltung rechtlicher Vorschriften erforderlich ist. Wir verpflichten sie darüber hinaus dazu, die Sicherheit und Vertraulichkeit der in unserem Auftrag verarbeiteten personenbezogenen Daten zu gewährleisten, indem geeignete technische und organisatorische Sicherungsmaßnahmen getroffen werden und Mitarbeiter mit Zugang zu personenbezogenen Daten zu Geheimhaltung verpflichtet sind
                            </p>
                            <p>
                                Eine Weitergabe der Daten an sonstige Dritte findet nicht statt.
                            </p>
                            <h4>Dauer der Speicherung</h4>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.
                            </p>
                            <p>
                                Dies ist für die während des Registrierungsvorgangs erhobenen Daten der Fall, wenn die Registrierung auf unserer Internetseite aufgehoben oder abgeändert wird. Die personenbezogenen Daten werden nur im Rahmen der Gutscheinversendung verarbeitet und spätestens drei Monate nach Beendigung der Aktion gelöscht.
                            </p>
                            <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                            <p>
                                Als Nutzer haben Sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit abändern lassen.
                            </p>
                            <p>
                                Die Löschung oder Änderung der Daten kann jederzeit schriftlich gegenüber K+R, Kennwort HVB 2020 per E-Mail an <a href={'mailto:info@mastercard-aktionsbuero.de'} className={'underlined'}>info@mastercard-aktionsbuero.de</a> verlangt werden.
                            </p>
                            <h3>VI.&nbsp;&nbsp;&nbsp;Rechte der betroffenen Personen</h3>
                            <p>
                                Werden Ihre personenbezogenen Daten verarbeitet, stehen Ihnen gegenüber dem Verantwortlichen die im Folgenden genannten Rechte zu.
                            </p>
                            <h4>Auskunftsrecht</h4>
                            <p>
                                Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
                            </p>
                            <p>
                                Liegt eine solche Verarbeitung vor, können Sie von uns über folgende Informationen Auskunft verlangen:
                            </p>
                            <ol>
                                <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
                                <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
                                <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</li>
                                <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</li>
                                <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</li>
                                <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                                <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</li>
                                <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absatz 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
                            </ol>
                            <p>
                                Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                            </p>
                            <h4>Recht auf Berichtigung</h4>
                            <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
                            <h4>Recht auf Einschränkung der Verarbeitung</h4>
                            <p>
                                Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
                            </p>
                            <ol>
                                <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</li>
                                <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
                                <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</li>
                                <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
                            </ol>
                            <p>
                                Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
                            </p>
                            <p>
                                Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
                            </p>
                            <h4>Recht auf Löschung</h4>
                            <p>
                                Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
                            </p>
                            <ol>
                                <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
                                <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
                                <li>Sie legen gemäß Artikel 21 Absatz 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Artikel 21 Absatz 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
                                <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                                <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
                                <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Artikel 8 Absatz 1 DSGVO erhoben.</li>
                            </ol>
                            <h4>Information an Dritte in Folge der Löschung</h4>
                            <p>
                                Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gemäß Artikel 17 Absatz 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
                            </p>
                            <h4>Ausnahmen vom Recht auf Löschung</h4>
                            <p>
                                Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
                            </p>
                            <ol>
                                <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
                                <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
                                <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Artikel 9 Absatz 2 Buchstabe h und i sowie Artikel 9 Absatz 3 DSGVO;</li>
                                <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Artikel 89 Absatz 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
                                <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
                            </ol>
                            <h4>Recht auf Unterrichtung</h4>
                            <p>
                                Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
                            </p>
                            <p>
                                Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
                            </p>
                            <h4>Recht auf Datenübertragbarkeit</h4>
                            <p>
                                Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
                            </p>
                            <ol>
                                <li>die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe b DSGVO beruht und</li>
                                <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
                            </ol>
                            <p>
                                In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
                            </p>
                            <p>
                                Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                            </p>
                            <h4>Widerspruchsrecht</h4>
                            <p>
                                Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Artikel 6 Absatz 1 Satz 1 Buchstabe e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                            </p>
                            <p>
                                Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                            </p>
                            <p>
                                Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                            </p>
                            <p>
                                Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
                            </p>
                            <p>
                                Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft ungeachtet der Richtlinie 2002/58/EG Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
                            </p>
                            <h4>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>
                            <p>
                                Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
                            </p>
                            <h4>Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h4>
                            <p>
                                Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung - einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
                            </p>
                            <ol>
                                <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
                                <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
                                <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
                            </ol>
                            <p>
                                Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Artikel 9 Absatz 1 DSGVO beruhen, sofern nicht Artikel 9 Absatz 2 Buchstabe a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
                            </p>
                            <p>
                                Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                            </p>
                            <h4>Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
                            <p>
                                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                            </p>
                            <p>
                                Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Artikel 78 DSGVO.
                            </p>
                            <h3>VII.&nbsp;&nbsp;&nbsp;E-Mail-Kontakt</h3>
                            <p>
                                Eine Kontaktaufnahme (bspw. bei Rückfragen zu Gutscheinen) ist über die E-Mail-Adresse <a href={'mailto:info@mastercard-aktionsbuero.de'} className={'underlined'}>info@mastercard-aktionsbuero.de</a> möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.
                            </p>
                            <p>
                                Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
                            </p>
                            <h4>Zweck</h4>
                            <p>
                                Die Daten werden verarbeitet, um das uns übermittelte Anliegen zu bearbeiten.
                            </p>
                            <h4>Rechtsgrundlage</h4>
                            <p>
                                Für die Verarbeitung Ihrer Daten und der zugehörigen Logfiles ist Artikel 6 Absatz 1 Buchstabe f DSGVO und, soweit sie eine solche abgegeben haben, Ihre Einwilligung Rechtsgrundlage.
                            </p>
                            <h4>Dauer der Speicherung</h4>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zweckes nicht mehr erforderlich sind.
                            </p>
                            <h4>Widerruf, Widerspruch und Ende der Verarbeitung</h4>
                            <p>
                                Ihre Daten werden bis zum Widerspruch gegen die Verarbeitung, des Widerrufs Ihrer Einwilligung oder bis zu dem Moment gespeichert, wo für uns ersichtlich ist, dass einer der sonstigen in Artikel 17 Absatz 1 DSGVO genannten Löschgründe vorliegt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    PopupboxManager.open({content, config: {fadeIn: true,fadeInSpeed: 200}})
}
const lightboxTandC = () => {
    const content = (
        <>
            <div className={'terms-and-conditions'}>
                <div className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-right'}>
                            <span className={'lb-close'} onClick={PopupboxManager.close}>Schließen</span>
                        </div>
                    </div>
                    <div className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-left'}>
                            <h1 className={'h2'}>Teilnahmebedingungen</h1>
                            <ol>
                                <li>
                                    Die Teilnahme an der Aktion richtet sich nach den nachstehend aufgeführten Bedingungen. Die Aktion wird von K+R GmbH, Ludwig-Erhard-Straße 6, 65760 Eschborn („<strong>K+R</strong>“) im Auftrag der HypoVereinsbank, UniCredit Bank AG, Arabellastraße 12, 81925 München („<strong>HVB</strong>“) durchgeführt.
                                </li>
                                <li>
                                    Die Aktion wird durchgeführt bis 31.12.2022, 23:59:59 Uhr MEZ.<br />
                                    Außerhalb dieses Zeitraums ist die Teilnahme nicht möglich. Im Aktionszeitraum nimmt der Teilnehmer durch Authentifizierung seiner HVB Mastercard (siehe 3.) unter Angabe seiner E-Mail-Adresse auf der aktionsbezogenen Webseite www.2fa-test.de, teil. Gleichfalls muss er den Teilnahmebedingungen zustimmen und die Datenschutzbelehrung zur Kenntnis nehmen.
                                </li>
                                <li>
                                    Voraussetzung für die Teilnahme ist die erfolgreiche 2-Faktor-Authentifizierung entweder via HVB ID Check Mobile App oder via SMS-Tan und eCode. Ein Zahlungsvorgang ist dagegen nicht nötig.
                                </li>
                                <li>
                                    Jede HVB Mastercard Kreditkarte kann im Aktionszeitraum einmal an der Aktion teilnehmen. Die Teilnahme pro E-Mail-Adresse ist auf 5-mal begrenzt.
                                </li>
                                <li>
                                    Teilnahmeberechtigt ist jeder Inhaber einer in Deutschland ausgegebenen HVB Mastercard<sup>®</sup>.
                                </li>
                                <li>
                                    Ausgeschlossen von der Teilnahme sind alle Mitarbeiter von K+R sowie deren Angehörige.
                                </li>
                                <li>
                                    Der Veranstalter behält sich vor, die Einhaltung der Teilnahmebedingungen zu überprüfen.
                                </li>
                                <li>
                                    Jeder Teilnehmer, der während des Aktionszeitraums wie vorstehend unter den Ziffern 2 bis 3 beschrieben seine Teilnahme bestätigt und eine erfolgreiche Mastercard® Identity Check™ Authentifizierung durchgeführt hat, erhält einen vorab aus unterschiedlichen Gutscheinangeboten ausgesuchten Gutschein.
                                </li>
                                <li>
                                    Es steht eine begrenzte Anzahl von Gutscheinen zur Verfügung. Die Verteilung der Gutscheine erfolgt solange der Vorrat reicht.
                                </li>
                                <li>
                                    Der Gutschein wird im Berechtigungsfalle per E-Mail zugesandt. Für die Gutscheine gelten die jeweils mit der Gutschein-E-Mail übermittelten Einlöse-Bedingungen sowie die Allgemeinen Geschäftsbedingungen des entsprechenden Onlineshops.
                                </li>
                                <li>
                                    Die Teilnahme an der Aktion ist freiwillig.
                                </li>
                                <li>
                                    Die Übertragung der Gutscheine an Dritte ist möglich. Sonstige Veräußerung (auch im Rahmen von Online-Auktionen) sowie die Barauszahlung hingegen sind ausgeschlossen.
                                </li>
                                <li>
                                    Der Veranstalter behält sich vor, die Aktion zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von Gründen einzustellen oder abzuändern, wenn die ordnungsgemäße Durchführung aufgrund tatsächlicher oder rechtlicher Hindernisse nicht mehr gewährleistet ist.
                                </li>
                                <li>
                                    Personen, die versuchen, sich selbst oder Dritten bei der Teilnahme durch Manipulation Vorteile zu verschaffen, können von der Teilnahme ausgeschlossen werden. Auf unberechtigte Weise erworbene Gutscheine können zurückgefordert werden.
                                </li>
                                <li>
                                    Die Haftung von K+R ist mit Ausnahme von Vorsatz und grober Fahrlässigkeit sowie bei der Verletzung wesentlicher Vertragspflichten und der Verletzung von Leib, Leben und Gesundheit ausgeschlossen.
                                </li>
                                <li>
                                    Es gilt ausschließlich das Recht der Bundesrepublik Deutschland.
                                </li>
                                <li>
                                    Sollten einzelne Bestimmungen dieser Teilnahmebedingungen ungültig sein, bleibt die Gültigkeit der übrigen Bedingungen hiervon unberührt.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    PopupboxManager.open({content, config: {fadeIn: true,fadeInSpeed: 200}})
}
const showPrivacyPolicy = () => {
    document.querySelector('.campaign-has-ended').classList.add('scroll');
    document.querySelector('#privacy-policy').setAttribute('style', 'display: block');
    document.querySelector('#campaign-has-ended').setAttribute('style', 'display: none');
}
const closePrivacyPolicy = () => {
    document.querySelector('.campaign-has-ended').classList.remove('scroll');
    document.querySelector('#privacy-policy').setAttribute('style', 'display: none');
    document.querySelector('#campaign-has-ended').setAttribute('style', 'display: block');
}
const showImprint = () => {
    document.querySelector('.campaign-has-ended').classList.add('scroll');
    document.querySelector('#imprint-1').setAttribute('style', 'display: block');
    document.querySelector('#campaign-has-ended').setAttribute('style', 'display: none');
}
const closeImprint = () => {
    document.querySelector('.campaign-has-ended').classList.remove('scroll');
    document.querySelector('#imprint-1').setAttribute('style', 'display: none');
    document.querySelector('#campaign-has-ended').setAttribute('style', 'display: block');
}
const lightboxCampaignHasEnded = () => {
    const content = (
        <>
            <div className={'campaign-has-ended'}>
                <header className={'layout-page-head lightbox'}>
                    <div className={'layout-page-head-inner'}>
                        <Link className={'brand-logo'} to={'/'}>
                            <HvbIcon width={192} />
                        </Link>
                    </div>
                </header>        
                <div className={'grid-container'}>
                    <div className={'grid-x grid-padding-x'} id={'campaign-has-ended'}>
                        <div className={'large-12 cell text-left'}>
                            <h2>Bequem und sicher online bezahlen mit Ihrer HVB Mastercard<sup>®</sup></h2>
                            <p className='h4'>Der Test-Shop ist beendet. Mehr Informationen zur 2-Faktor-Authentifizierung finden Sie auf der HVB-Website:</p>
                            <Link to={{javascript:void(0)}} className={'button btn-primary'} onClick={() => window.open('https://hvb.de/idcheck')}>Jetzt mehr erfahren</Link>
                            <div>
                                <Link to={{javascript:void(0)}} onClick={showImprint}>Impressum / </Link>
                                <Link to={{javascript:void(0)}} onClick={showPrivacyPolicy}>Datenschutz</Link>
                            </div>
                        </div>
                    </div>
                    <div id={'imprint-1'} className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-right'}>
                            <span className={'lb-close'} onClick={closeImprint}>Schließen</span>
                        </div>
                        <div className={'large-12 cell text-left'}>
                            <h2>Impressum</h2>
                            <h3>ANGABEN GEMÄß § 5 TMG</h3>
                            <p>
                                K+R GmbH<br />
                                Ludwig-Erhard-Straße 6<br />
                                65760 Eschborn
                            </p>
                            <h3>VERTRETEN DURCH</h3>
                            <p>
                                Michael Roschanski<br />
                                Andreas M. Denk<br />
                                Simone Zwecker
                            </p>
                            <h3>KONTAKT</h3>
                            <p>
                                Telefon:&nbsp;+49 6196 93063-0<br />
                                Telefax:&nbsp;+49 6196 93063-45<br />
                                E-Mail:&nbsp;&nbsp;<a href={'mailto:info@krgroup.de'} className={'underlined'}>info@krgroup.de</a>
                            </p>
                            <h3>REGISTEREINTRAG</h3>
                            <p>
                                Eintragung im Handelsregister.<br />
                                Registergericht: Frankfurt am Main<br />
                                Registernummer: HRB 40140
                            </p>
                            <h3>UMSATZSTEUER-ID</h3>
                            <p>
                                Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE161720540<br />
                                Verantwortlich für den Inhalt nach § 55 Abs.2
                            </p>
                            <h3>RSTV</h3>
                            <p>
                                Michael Roschanski<br />
                                Andreas M. Denk<br />
                                Simone Zwecker
                            </p>
                            <p>
                                K+R GmbH<br />
                                Ludwig-Erhard-Straße 6<br />
                                65760 Eschborn
                            </p>
                        </div>
                    </div>
                    <div id={'privacy-policy'} className={'grid-x grid-padding-x'}>
                        <div className={'large-12 cell text-right'}>
                            <span className={'lb-close'} onClick={closePrivacyPolicy}>Schließen</span>
                        </div>
                        <div className={'large-12 cell text-left'}>
                            <h2>Datenschutzbestimmungen</h2>
                            <h3>I.&nbsp;&nbsp;&nbsp;Name und Anschrift des Verantwortlichen</h3>
                            <p>
                                Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (im Folgenden „DSGVO“) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
                            </p>
                            <p>
                                K+R GmbH<br />
                                Ludwig-Erhard-Straße 6<br />
                                65760 Eschborn
                            </p>
                            <p>
                                Tel. 06196 9306330<br />
                                Fax 06196 93063 45<br />
                                <a href={'mailto:info@krgroup.de'} className={'underlined'}>info@krgroup.de</a>
                            </p>
                            <p>
                                (im Folgenden „Verantwortlicher“ oder „K+R“)
                            </p>
                            <h3>II.&nbsp;&nbsp;&nbsp;Name und Anschrift des Datenschutzbeauftragten</h3>
                            <h4>Der Datenschutzbeauftragte des Verantwortlichen ist:</h4>
                            <p>
                                Weißmann Datenschutz GmbH<br />
                                RA Benedikt Rudolph<br />
                                Anschrift:<br />
                                Bosenheimer Str. 10, 55543 Bad Kreuznach<br />
                                Tel.: +49 (0) 30 2359 92171<br />
                                Fax: +49 (0) 30 2359 92178<br />
                                E-Mail: Datenschutz@kanzlei-weissmann.de
                            </p>
                            <h3>III.&nbsp;&nbsp;&nbsp;Allgemeines zur Datenverarbeitung</h3>
                            <h4>1.&nbsp;&nbsp;Umfang der Verarbeitung personenbezogener Daten</h4>
                            <p>
                                Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies für die Veranstaltung dieser Aktion, zur Bereitstellung einer funktionsfähigen Website, unserer Inhalte und zur Erbringung unserer Leistungen und etwaiger damit verbundener Nebenleistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
                            </p>
                            <h4>2.&nbsp;&nbsp;Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
                            <p>
                                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient diese in Verbindung mit Artikel 6 Absatz 1 Satz 1 Buchstabe a und Artikel 7 DSGVO als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
                            </p>
                            <p>
                                Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, ist Artikel 6 Absatz 1 Satz 1 Buchstabe b DSGVO Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Beispielsweise kann dies die Verarbeitung von Daten zur Durchführung einer solchen Aktion sein.
                            </p>
                            <p>
                                Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person das erstgenannte Interesse nicht, so dient uns Artikel 6 Absatz 1 Satz 1 Buchstabe f DSGVO als Rechtsgrundlage für die Verarbeitung.
                            </p>
                            <h4>3.&nbsp;&nbsp;Datenlöschung, Einschränkung der Datenverarbeitung und Speicherdauer</h4>
                            <p>
                                Soweit erforderlich, verarbeiten wir Ihre Daten für die Dauer dieser Aktion und der anschließenden Abwicklung und Gutscheinversendung.
                            </p>
                            <p>
                                Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald die Gewinnabwicklung erfolgt ist. Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                            </p>
                            <p>
                                Die Verarbeitung der Daten wird eingeschränkt, wenn
                            </p>
                            <p>
                                a) die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
                            </p>
                            <p>
                                b) die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;
                            </p>
                            <p>
                                c) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder
                            </p>
                            <p>
                                d) die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
                            </p>
                            <h4>4.&nbsp;&nbsp;Schutz personenbezogener Daten</h4>
                            <p>
                                Wir haben auf Basis des gesetzlich geforderten risikoorientierten Ansatzes technische und organisatorische Maßnahmen umgesetzt, um personenbezogene Daten gegen zufällige oder vorsätzliche Manipulationen, gegen Verlust, Zerstörung oder den Zugriff Unberechtigter zu schützen. Unsere Sicherheitsmaßnahmen werden unter anderem entsprechend der technologischen Entwicklung fortlaufend kontrolliert und verbessert.
                            </p>
                            <h3>IV.&nbsp;&nbsp;&nbsp;Bereitstellung der Website</h3>
                            <h3>Datenverarbeitung im Zusammenhang mit der Nutzung unserer Website und der Teilnahme an der Aktion</h3>
                            <h4>1.&nbsp;&nbsp;Webauftritt und Logfiles</h4>
                            <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                            <p>
                                Bei jeder Nutzung unseres Webauftritts werden Daten zwischen dem Client (z. B. Ihrem Web-Browser) und dem Server (unser Webauftritt) ausgetauscht. Dabei werden automatisch personenbezogene Daten von uns erhoben und auf unserem Server gespeichert. Auf unserem Server werden hierfür die nachfolgenden Informationen gespeichert (Datum und Uhrzeit des Zugriffs, Typ und Version Ihres Internet-Browsers, Typ und Version des Betriebssystems, volle IP-Adresse des Nutzers, Internet-Service-Provider des Nutzers, aufgerufene Seiten des Webauftritts, Datenmenge der angeforderten Inhalte unserer Website, die zuvor besuchte Seite / Referrer URL)
                            </p>
                            <h5>Zweck</h5>
                            <p>
                                Diese Informationen werden von uns ausschließlich für die nachfolgenden Zwecke genutzt: Erstellung von Nutzungsstatistiken und Statistiken des IT-Betriebs, Optimierung unserer Inhalte, Sicherstellung des Betriebs oder der Betriebssicherheit, Analyse bei Verdacht auf eine rechtswidrige Nutzung unserer Website.
                            </p>
                            <h5>Rechtsgrundlage</h5>
                            <p>
                                Rechtsgrundlage für die vorübergehende Speicherung dieser Daten und der zugehörigen Logfiles ist Artikel 6 Absatz 1 Satz 1 Buchstabe f DSGVO, wobei unser berechtigtes Interesse in der Erfüllung der oben genannten Zwecke liegt.
                            </p>
                            <h5>Dauer der Speicherung</h5>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zweckes nicht mehr erforderlich sind.
                            </p>
                            <h5>Widerruf, Widerspruch und Ende der Verarbeitung</h5>
                            <p>
                                Der Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles kann effektiv durch Verlassen der Internetseite widersprochen werden. Soweit personenbezogene Daten bereits gespeichert sind, richten Sie Ihren etwaigen Widerspruch bitte an die eingangs genannten Kontaktdaten. Soweit die Daten für die Sicherung und den Betrieb der Website zwingend erforderlich sind besteht keine Widerspruchsmöglichkeit.
                            </p>
                            <h4>2.&nbsp;&nbsp;Technisch notwendige Cookies</h4>
                            <h5>Beschreibung und Umfang der Datenverarbeitung</h5>
                            <p>
                                Cookies sind Informationsdateien, die beim Abruf unserer Website auf Ihrem verwendeten Endgerät abgelegt werden, insbesondere, um diese Informationen zu einem späteren Zeitpunkt wieder nutzen zu können. Cookies speichern Informationen, wie beispielsweise Ihre Besuchsdauer auf unserer Website oder auch Ihre dort getätigten Eingaben.
                            </p>
                            <p>
                                Bei jeder Nutzung unseres Webauftritts werden die nachfolgenden Informationen in Cookies abgelegt: Session Cookies, damit die Seiteninhalte in einer Weise angeboten werden können, die einem modernen Nutzererlebnis entspricht. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
                            </p>
                            <h5>Zweck</h5>
                            <p>
                                Zweck dieser Datenverarbeitung ist es, die Betriebsfähigkeit unseres Webauftritts zu gewährleisten.
                            </p>
                            <h5>Rechtsgrundlage</h5>
                            <p>
                                Da wir die Cookies verwenden, um einen ordnungsgemäßen Betrieb unserer Website zu gewährleisten, stützen wir die Datenverarbeitung auf unser berechtigtes Interesse gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe f DSGVO.
                            </p>
                            <h5>Dauer der Speicherung</h5>
                            <p>
                                Die Daten werden mit Löschung eines Cookies gelöscht. Das kann im Cookie hinterlegt sein oder durch den User aktiv über den Browser veranlasst werden. Die handelsüblichen Browser bieten diese Möglichkeit, Cookies seitens des Users zu löschen.
                            </p>
                            <h5>Widerruf, Widerspruch und Ende der Verarbeitung</h5>
                            <p>
                                Der Erfassung der Daten durch Cookies und der Speicherung der Daten kann effektiv durch Verlassen des Webangebots widersprochen werden. Soweit personenbezogene Daten bereits gespeichert sind, löschen Sie mit der dafür vorgesehenen Browser-Funktionalität bitte die Cookies und richten Sie etwaige darüberhinausgehende Widersprüche bitte an die eingangs genannten Kontaktdaten.
                            </p>
                            <h4>3.&nbsp;&nbsp;Technisch nicht notwendige Cookies</h4>
                            <p>Technisch nicht notwendige Cookies werden von uns nicht verwendet.</p>
                            <h3>V.&nbsp;&nbsp;&nbsp;Authentifizierungsprozess</h3>
                            <h4>Beschreibung und Umfang der Datenverarbeitung</h4>
                            <p>
                                Im Rahmen der Nutzung der Plattform erfolgt eine ID-Check Authentifizierung – bei der kein Zahlungsvorgang stattfindet. In diesem Zusammenhang gibt der Teilnehmer seine E-Mail-Adresse sowie seine Kreditkartennummer und Ablaufdatum ein.
                            </p>
                            <p>
                                Die vom Teilnehmer eingegebenen Kreditkartendaten werden, mit Ausnahme der BIN-Nummer, nicht selbst vom Verantwortlichen verarbeitet. Die Daten fließen direkt an den Dienstleister Adyen N.V., Simon Carmiggeltstraat 6-50, 1011 DJ Amsterdam, Niederlande. Der Verantwortliche oder andere Dienstleister des Verantwortlichen erlangen keine Kenntnis von den vollständigen Kartendaten.
                            </p>
                            <p>
                                Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, einen Gutschein aus einer Auswahl zu wählen, und diesen per E-Mail zugesendet zu bekommen. Die E-Mail-Adresse wird dazu vom Teilnehmer an uns übermittelt und bei uns gespeichert.
                            </p>
                            <p>
                                Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten werden im Rahmen der Nutzung der Plattform erhoben:
                            </p>
                            <ol>
                                <li><i className={'underlined'}>Nutzungsdaten:</i> Zeitpunkt, Tag, Dauer der Nutzung, ausgeführte Interaktionen, Websites, von denen das System des Nutzers auf unsere Internetseite gelangt, Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
                                <li><i className={'underlined'}>Nutzerdaten:</i> Informationen über den Browsertyp und die verwendete Version, Betriebssystem des Nutzers, Internet-Service-Provider des Nutzers, IP-Adresse des Nutzers</li>
                                <li><i className={'underlined'}>Registrierungsdaten:</i> E-Mailadresse, Kreditkartennummer inkl. BIN, Ablaufdatum der Kreditkarte</li>
                                <li><i className={'underlined'}>Gutscheinabruf:</i> Zeitpunkt</li>
                            </ol>
                            <p>
                                Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt
                            </p>
                            <h4>Rechtsgrundlage für die Datenverarbeitung</h4>
                            <p>
                                Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                            </p>
                            <h4>Zweck der Datenverarbeitung</h4>
                            <p>
                                Eine Registrierung des Nutzers ist für die Teilnahme an der Aktion erforderlich. Wir verwenden die erhobenen personenbezogenen Daten zu den folgenden Zwecken:
                            </p>
                            <p>
                                Bereitstellung der Kampagne und Kommunikation, u. a. zur elektronischen Versendung der Gutscheine.
                            </p>
                            <h4>Weitergabe der Daten an Dritte</h4>
                            <p>
                                Die erhobenen personenbezogenen Daten werden im Rahmen der Gutscheinversendung ggf. an folgende beteiligte Unternehmen weitergegeben:
                            </p>
                            <ul>
                                <li>CAMAO Darmstadt GmbH, Europaplatz 5, 64293 Darmstadt, Deutschland</li>
                                <li>Adyen N.V., Simon Carmiggeltstraat 6-50, 1011 DJ Amsterdam, Niederlande</li>
                                <li>Dienstleister, die in unserem Namen und Auftrag handeln.</li>
                            </ul>
                            <p>
                                Wir verpflichten diese Dienstleister vertraglich dazu, personenbezogene Daten ausschließlich gemäß unseren Anweisungen zu verarbeiten und soweit dies zur Erbringung von Dienstleistungen oder zur Einhaltung rechtlicher Vorschriften erforderlich ist. Wir verpflichten sie darüber hinaus dazu, die Sicherheit und Vertraulichkeit der in unserem Auftrag verarbeiteten personenbezogenen Daten zu gewährleisten, indem geeignete technische und organisatorische Sicherungsmaßnahmen getroffen werden und Mitarbeiter mit Zugang zu personenbezogenen Daten zu Geheimhaltung verpflichtet sind
                            </p>
                            <p>
                                Eine Weitergabe der Daten an sonstige Dritte findet nicht statt.
                            </p>
                            <h4>Dauer der Speicherung</h4>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.
                            </p>
                            <p>
                                Dies ist für die während des Registrierungsvorgangs erhobenen Daten der Fall, wenn die Registrierung auf unserer Internetseite aufgehoben oder abgeändert wird. Die personenbezogenen Daten werden nur im Rahmen der Gutscheinversendung verarbeitet und spätestens drei Monate nach Beendigung der Aktion gelöscht.
                            </p>
                            <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                            <p>
                                Als Nutzer haben Sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit abändern lassen.
                            </p>
                            <p>
                                Die Löschung oder Änderung der Daten kann jederzeit schriftlich gegenüber K+R, Kennwort HVB 2020 per E-Mail an <a href={'mailto:info@mastercard-aktionsbuero.de'} className={'underlined'}>info@mastercard-aktionsbuero.de</a> verlangt werden.
                            </p>
                            <h3>VI.&nbsp;&nbsp;&nbsp;Rechte der betroffenen Personen</h3>
                            <p>
                                Werden Ihre personenbezogenen Daten verarbeitet, stehen Ihnen gegenüber dem Verantwortlichen die im Folgenden genannten Rechte zu.
                            </p>
                            <h4>Auskunftsrecht</h4>
                            <p>
                                Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
                            </p>
                            <p>
                                Liegt eine solche Verarbeitung vor, können Sie von uns über folgende Informationen Auskunft verlangen:
                            </p>
                            <ol>
                                <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
                                <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
                                <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</li>
                                <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</li>
                                <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</li>
                                <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                                <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</li>
                                <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absatz 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
                            </ol>
                            <p>
                                Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                            </p>
                            <h4>Recht auf Berichtigung</h4>
                            <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
                            <h4>Recht auf Einschränkung der Verarbeitung</h4>
                            <p>
                                Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
                            </p>
                            <ol>
                                <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</li>
                                <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
                                <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</li>
                                <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
                            </ol>
                            <p>
                                Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
                            </p>
                            <p>
                                Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
                            </p>
                            <h4>Recht auf Löschung</h4>
                            <p>
                                Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
                            </p>
                            <ol>
                                <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
                                <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
                                <li>Sie legen gemäß Artikel 21 Absatz 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Artikel 21 Absatz 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
                                <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                                <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
                                <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Artikel 8 Absatz 1 DSGVO erhoben.</li>
                            </ol>
                            <h4>Information an Dritte in Folge der Löschung</h4>
                            <p>
                                Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gemäß Artikel 17 Absatz 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
                            </p>
                            <h4>Ausnahmen vom Recht auf Löschung</h4>
                            <p>
                                Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
                            </p>
                            <ol>
                                <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
                                <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
                                <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Artikel 9 Absatz 2 Buchstabe h und i sowie Artikel 9 Absatz 3 DSGVO;</li>
                                <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Artikel 89 Absatz 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
                                <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
                            </ol>
                            <h4>Recht auf Unterrichtung</h4>
                            <p>
                                Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
                            </p>
                            <p>
                                Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
                            </p>
                            <h4>Recht auf Datenübertragbarkeit</h4>
                            <p>
                                Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
                            </p>
                            <ol>
                                <li>die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe b DSGVO beruht und</li>
                                <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
                            </ol>
                            <p>
                                In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
                            </p>
                            <p>
                                Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                            </p>
                            <h4>Widerspruchsrecht</h4>
                            <p>
                                Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Artikel 6 Absatz 1 Satz 1 Buchstabe e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                            </p>
                            <p>
                                Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                            </p>
                            <p>
                                Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                            </p>
                            <p>
                                Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
                            </p>
                            <p>
                                Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft ungeachtet der Richtlinie 2002/58/EG Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
                            </p>
                            <h4>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>
                            <p>
                                Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
                            </p>
                            <h4>Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h4>
                            <p>
                                Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung - einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
                            </p>
                            <ol>
                                <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
                                <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
                                <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
                            </ol>
                            <p>
                                Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Artikel 9 Absatz 1 DSGVO beruhen, sofern nicht Artikel 9 Absatz 2 Buchstabe a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
                            </p>
                            <p>
                                Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                            </p>
                            <h4>Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
                            <p>
                                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                            </p>
                            <p>
                                Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Artikel 78 DSGVO.
                            </p>
                            <h3>VII.&nbsp;&nbsp;&nbsp;E-Mail-Kontakt</h3>
                            <p>
                                Eine Kontaktaufnahme (bspw. bei Rückfragen zu Gutscheinen) ist über die E-Mail-Adresse <a href={'mailto:info@mastercard-aktionsbuero.de'} className={'underlined'}>info@mastercard-aktionsbuero.de</a> möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.
                            </p>
                            <p>
                                Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
                            </p>
                            <h4>Zweck</h4>
                            <p>
                                Die Daten werden verarbeitet, um das uns übermittelte Anliegen zu bearbeiten.
                            </p>
                            <h4>Rechtsgrundlage</h4>
                            <p>
                                Für die Verarbeitung Ihrer Daten und der zugehörigen Logfiles ist Artikel 6 Absatz 1 Buchstabe f DSGVO und, soweit sie eine solche abgegeben haben, Ihre Einwilligung Rechtsgrundlage.
                            </p>
                            <h4>Dauer der Speicherung</h4>
                            <p>
                                Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zweckes nicht mehr erforderlich sind.
                            </p>
                            <h4>Widerruf, Widerspruch und Ende der Verarbeitung</h4>
                            <p>
                                Ihre Daten werden bis zum Widerspruch gegen die Verarbeitung, des Widerrufs Ihrer Einwilligung oder bis zu dem Moment gespeichert, wo für uns ersichtlich ist, dass einer der sonstigen in Artikel 17 Absatz 1 DSGVO genannten Löschgründe vorliegt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    PopupboxManager.open({content, config: {overlayClose: false, escClose: false, fadeIn: false, overlayOpacity: 0}})
}


const Footer = props => {
    const campaignHasEnded = props.campaignHasEnded
    useEffect(() => {
        switch (campaignHasEnded) {
            case true:
                document.querySelector('body').classList.add('campaign-has-ended-body');
                new Promise(resolve => {
                    resolve(lightboxCampaignHasEnded())
                }).then(() => {
                    document.querySelector('#imprint-1').setAttribute('style', 'display: none');
                    document.querySelector('#privacy-policy').setAttribute('style', 'display: none');
                }).catch((error) => {
                    console.log(error);
                });
                break;
            default:
                break;
        }
    }, []);
    return (
        <footer className={'layout-page-footer'}>
            <div className={'layout-page-footer-inner'}>
                <div className="footer-legal">
                    <div className="footer-legal-inner grid-container">
                        <div className={'grid-x grid-padding-x'}>
                            <div className={'large-12 cell'}>
                                <div className={'flex-wrapper'}>
                                    <img src={require('../../assets/images/icons/mc_idcheck_vrt_neg.png')} alt="Mastercard ID Check" />
                                    <div className="footer-copyright">© 2021 K+R GmbH</div>
                                    <nav className="footer-meta">
                                        <ul className={'reset-list'}>
                                            <li><Link id={'imprint'} to={{javascript:void(0)}} onClick={lightboxImprint}>Impressum</Link></li>
                                            <li><Link id={'privacy'} to={{javascript:void(0)}} onClick={lightboxPrivacyPolicy}>Datenschutzbestimmungen</Link></li>
                                            <li><Link id={'terms'} to={{javascript:void(0)}} onClick={lightboxTandC}>Teilnahmebedingungen</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
