import React from 'react';
import {routes} from './config/router';
import ClearCache from 'react-clear-cache';

// import {BrowserRouter as Router} from 'react-router-dom';
// alternative: use Hash for routing (#/path)
import {HashRouter as Router} from 'react-router-dom';
import {Switch} from 'react-router-dom';
import Routes from './components/Router/Routes';
import AuthenticationProvider from './components/Authentication/AuthenticationProvider';
import ProductsContextProvider from './contexts/ProductsContext';
import CartContextProvider from './contexts/CartContext';
import './App.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Lightbox from "./components/Lightbox/Lightbox";
const date = new Date();
const campaignHasEnded = date.getFullYear() != 2022;
const App = props => {
    return (
        <>
            <AuthenticationProvider>
                <ProductsContextProvider>
                    <CartContextProvider>
                        <Router>
                            <Header />
                            <Switch>
                                <Routes routes={routes}/>
                            </Switch>
                            <Footer campaignHasEnded={campaignHasEnded} />
                            <Lightbox />
                        </Router>
                    </CartContextProvider>
                </ProductsContextProvider>
            </AuthenticationProvider>
        </>
    );
};

export default App;
