import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "./Spinner.scss";


const Spinner = props => {
    return(
        <>
            {props.loading ?
                <div className={'Spinner'}>
                    <PulseLoader color={'#f99f1d'} size={10} margin={5} loading={true} />
                </div>
            : null}
        </>
    )
}

export default Spinner;
