import React, {useEffect} from "react";
import {Trans} from "react-i18next";
const waitFor = (ms) => new Promise(r => setTimeout(r, ms));
const checkForElement = async(callback) => { // check if adyen form DOM Tree is loaded
    await waitFor(50);
    let adyenCheckoutCardForm = document.querySelector('.adyen-checkout__card-input__form');
    let adyenCheckoutCardNumberElement = adyenCheckoutCardForm.querySelector('span[data-cse="encryptedCardNumber"]');
    if(adyenCheckoutCardNumberElement.childElementCount !== 0) {
        return callback();
    } else {
        return checkForElement(callback);
    }
};
const disableButton = (dropinButton) => { // disabling submit button
    dropinButton.setAttribute('disabled', 'true');
}
const enableButton = (dropinButton) => { // enabling submit button
    dropinButton.removeAttribute('disabled');
}
const DropInButton = props => {
    useEffect(() => {
        checkForElement(() => {
            const config = {attributes: true};
            const dropinButton = document.querySelector('button.adyen-checkout__button');
            disableButton(dropinButton);
            dropinButton.querySelector('.adyen-checkout__button__content').addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropinButton.click();
            }, false);
            let adyenCheckoutCardForm = document.querySelector('.adyen-checkout__card-input__form');
            let adyenCheckoutCardNumberElement = adyenCheckoutCardForm.querySelector('span[data-cse="encryptedCardNumber"]');
            let adyenCheckoutCardExpiringElement = adyenCheckoutCardForm.querySelector('span[data-cse="encryptedExpiryDate"]');
            let adyenCheckoutCardNumberNode = adyenCheckoutCardNumberElement.parentElement.childNodes[0];
            let adyenCheckoutCardExpiringNode = adyenCheckoutCardExpiringElement.parentElement.childNodes[0];
            const cardFormCallback = (mutationsList, observer) => { // observe if attributes of input wrapper are changing (check if values are valid)
                let isValidCardNumber = adyenCheckoutCardNumberElement.hasAttribute('isvalid');
                let isValidExpiryDate = adyenCheckoutCardExpiringElement.hasAttribute('isvalid');
                let isValid = isValidCardNumber && isValidExpiryDate;
                if (isValid) { // enable button if values are valid
                    enableButton(dropinButton);
                } else {
                    disableButton(dropinButton);
                }
            }
            const observer = new MutationObserver(cardFormCallback);
            observer.observe(adyenCheckoutCardNumberNode, config); // observe credit card number
            observer.observe(adyenCheckoutCardExpiringNode, config); // observe credit card expiring date
        })
    }, [])

    return (
        <button className="adyen-checkout__button adyen-checkout__button--pay button btn-primary" type="button" {...props}>
            <span className="adyen-checkout__button__content">
                <span className="adyen-checkout__button__text">
                    Jetzt testen und Gutschein sichern
                </span>
            </span>
        </button>
    );
};

export default DropInButton;
