import React, { useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';

import CartItem from './CartItem';
import './CartProducts.scss';

const CartProducts = () => {
    const { cartItems } = useContext(CartContext);
    return (
        <div className={'large-12 cell CartProducts'}>
            {
                cartItems.map(product =>  <CartItem key={product.id} product={product}/>)
            }
        </div>
     );
}

export default CartProducts;
